import React from 'react'
import 'font-awesome/css/font-awesome.min.css';

const ModalPopup = (props) => {
    return (
        <section className='modal-popup'>
            <div className='popUp'>
                <div>
                    <div className='fontIcon'><i className="fa fa-check-circle"></i></div>
                    <h2>{props.Message}</h2>
                    <a href={props.LinkAnchor} style={{ textDecoration: "none" }}><button type="button" className="ModelBtn">{props.LinkMessage}</button></a>
                </div>
            </div>
        </section >


    )
}

export default ModalPopup


