import React, { useState, useEffect } from "react";
import { Alert } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import Logo from "../../assets/images/Logo.svg";
import pathNames from "../../router/RoutePath";
import { useDispatch, useSelector } from 'react-redux';
import { ResentLinkAction } from '../../redux/actions/AuthActions';

export default function ResendLink() {

    const history = useHistory();
    const dispatch = useDispatch();
    let [rsSuccessMessage, setRsSuccessMessage] = useState("")
    let [rsSuccessAlert, setRsSuccessAlert] = useState(false);
    let [rsErrorMessage, setRsErrorMessage] = useState("");
    let [rsErrorAlert, setRsErrorAlert] = useState(false);
    let [loading, setLoading] = useState(false);
    let [error, setError] = useState({ email: "" });
    let [fields, setState] = useState({
        email: "",
    });
    let emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    const ResendUserVerification = (e) => {
        e.preventDefault();
        let errorStatus = isErrorsObjectEmpty(error);
        if (errorStatus) {
            setLoading(true);
            setRsErrorMessage('');
            dispatch(ResentLinkAction(fields, history));
        }
        else {
            setRsErrorMessage('Please Fulfill all requirements.');
        }
    };

    const handleFieldChange = e => {
        setLoading(false);
        setRsErrorMessage("");
        setRsSuccessAlert("");
        let name = e.target.id;
        if (e.target.value !== "") {
            checkValidity(e.target.id, e.target.value);
            setState({
                ...fields,
                [e.target.id]: e.target.value
            })
        }
        else {
            setError({ ...error, [name]: "" });
        }
    }

    const checkValidity = (inputName, inputValue) => {
        const errorStringEmail = "Your email must be more than 8 characters long. Should be valid Email.";
        switch (inputName) {
            case "email":
                fields.email = emailRegex.test(inputValue);
                inputValue.length < 8 ? setError({ ...error, [inputName]: errorStringEmail }) : setError({ ...error, [inputName]: "" });
                if (!fields.email) setError({ ...error, [inputName]: errorStringEmail });
                break;
            default:
                break;
        }
    };

    let counterResetLink = useSelector((state) => state.userAuth.ResetLinkResponse);

    useEffect(() => {
        setRsSuccessMessage("");
        setRsErrorMessage("");
        if (counterResetLink) {
            let status = counterResetLink.status;
            let message = counterResetLink.message;
            if (status === true) {
                resetFormData('resend-link-form');
                setRsSuccessMessage(counterResetLink.message);
            }
            else if (status === false && message === "Validation Errors") {
                setRsSuccessMessage("");
                setRsErrorMessage(counterResetLink.data);
                resetFormData('resend-link-form');
            }
            else if (status === false && (message === "Email already Verified")) {
                setRsSuccessMessage(counterResetLink.message);
                resetFormData('resend-link-form');
            }
            else if (status === false && (message === "This user does not exist")) {
                setRsSuccessMessage("");
                setRsErrorMessage(counterResetLink.message);
                resetFormData('resend-link-form');
            }
            else if (status === false) {
                setRsSuccessMessage("");
                setRsErrorMessage(counterResetLink.message);
                resetFormData('resend-link-form');
            }
        }
    }, [counterResetLink])

    function isErrorsObjectEmpty(obj) {
        if (obj.email.length === 0) {
            return true;
        }
        return false;
    }

    useEffect(() => {
        if (rsErrorMessage !== "") {
            setRsErrorAlert(true);
            setLoading(false);
        } else {
            setRsErrorAlert(false);
            setLoading(false);
        }
    }, [rsErrorMessage])

    useEffect(() => {
        if (rsSuccessMessage !== "") {
            setLoading(false);
            setRsSuccessAlert(true);
        }
        else {
            setRsSuccessAlert(false);
            setLoading(false);
        }
    }, [rsSuccessMessage])

    function resetFormData(formId) {
        document.getElementById(formId).reset();
    }

    return (
        <section className="loginSection">
            <div className="container-fluid g-0">
                <div className="row g-0">
                    <div className="col-md-6" >
                        <div className="backgroundImage"></div>
                    </div>
                    <div className="col-md-6" >
                        <div className="mainLogin">
                            <div className="logo">
                                <img src={Logo} alt="" />
                            </div>
                            <div className="mainContent">
                                <h2 className="mb-0">Account not Verified?</h2>
                                <p className="mb-5">Enter the email address associated with your account.</p>
                                {rsErrorMessage && rsErrorAlert !== false && (<Alert variant="danger" onClose={() => setRsErrorAlert(false)} dismissible>{rsErrorMessage}</Alert>)}
                                {rsSuccessMessage && rsSuccessAlert !== false && (<Alert variant="success" onClose={() => setRsSuccessAlert(false)} dismissible>{rsSuccessMessage}</Alert>)}
                                <form id="resend-link-form" onSubmit={ResendUserVerification}>
                                    <div className="formInput mb-5">
                                        <label>Email</label>
                                        <input type="email" placeholder="johndoe@gmail.com" id="email" onChange={handleFieldChange} maxLength="50" required="required" />
                                        {error.email.length > 0 && <span className='error'>{error.email}</span>}
                                    </div>
                                    {loading ? <div className="loader"></div> :
                                        <button disabled={loading} className="btnLogin" type="submit">
                                            <span id="button-text">
                                                {loading ? <div className="loader"></div> : "Send Verification Link"}
                                            </span>
                                        </button>
                                    }
                                </form>
                                <div className="signUpLink">
                                    <span>
                                        <Link to={pathNames.login} >Back to Login</Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}