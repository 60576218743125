import * as ActionTypes from '../ActionTypes';
import {
    FetchStripePlansService,
    FetchUserSubscriptionDataService,
    FetchStripePlanService,
    CreatePaymentIntentService,
    UpdateUserSubscriptionService
} from '../../services/PaymentService';


// Stripe Plans Fetch
export const FetchStripePlansAction = () => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
        dispatch({ type: ActionTypes.LOADING });
        FetchStripePlansService().then((res) => {
            if (res.hasOwnProperty('status') && res.status === true) {
                dispatch({ type: ActionTypes.STRIPE_PLANS_FETCH_SUCCESS, res });
            } else if (res.hasOwnProperty('status') && res.status === false) {
                dispatch({ type: ActionTypes.STRIPE_PLANS_FETCH_ERROR, res });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
        })
    }
}

// Stripe Plan Fetch
export const FetchStripePlanAction = (constraints) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
        dispatch({ type: ActionTypes.LOADING });
        FetchStripePlanService(constraints).then((res) => {
            if (res.hasOwnProperty('status') && res.status === true) {
                dispatch({ type: ActionTypes.STRIPE_PLAN_FETCH_SUCCESS, res });
            } else if (res.hasOwnProperty('status') && res.status === false) {
                dispatch({ type: ActionTypes.STRIPE_PLAN_FETCH_ERROR, res });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
        })
    }
}

// Stripe Plan Fetch
export const CreatePaymentIntentAction = (constraints) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
        dispatch({ type: ActionTypes.LOADING });
        CreatePaymentIntentService(constraints).then((res) => {
            if (res.hasOwnProperty('status') && res.status === true) {
                dispatch({ type: ActionTypes.STRIPE_PAYMENT_INTENT_CREATE_SUCCESS, res });
            } else if (res.hasOwnProperty('status') && res.status === false) {
                dispatch({ type: ActionTypes.STRIPE_PAYMENT_INTENT_CREATE_ERROR, res });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
        })
    }
}

export const FetchUserSubscriptionDataAction = () => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
        dispatch({ type: ActionTypes.LOADING });
        FetchUserSubscriptionDataService().then((res) => {
            if (res.hasOwnProperty('status') && res.status === true) {
                dispatch({ type: ActionTypes.USER_SUBSCRIPTION_FETCH_SUCCESS, res });
            } else if (res.hasOwnProperty('status') && res.status === false) {
                dispatch({ type: ActionTypes.USER_SUBSCRIPTION_FETCH_ERROR, res });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
        })
    }
}

// Stripe Subscription Update
export const UpdateUserSubscriptionAction = (constraints) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
        dispatch({ type: ActionTypes.LOADING });
        UpdateUserSubscriptionService(constraints).then((res) => {
            if (res.hasOwnProperty('status') && res.status === true) {
                dispatch({ type: ActionTypes.USER_SUBSCRIPTION_UPDATE_SUCCESS, res });
            } else if (res.hasOwnProperty('status') && res.status === false) {
                dispatch({ type: ActionTypes.USER_SUBSCRIPTION_UPDATE_ERROR, res });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
        })
    }
}
