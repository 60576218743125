import React, { useState, useEffect } from "react";
import { useHistory, Link } from 'react-router-dom';
import CheckIcon from "../assets/images/Check_Icon.svg"
import CrossIcon from "../assets/images/Cross_Icon.svg"
import homeLogo from "../assets/images/home_icon.svg"
import rightArrow from "../assets/images/right_arrow.svg"
import pathNames from "../router/RoutePath";
import { useDispatch, useSelector } from 'react-redux';
import { FetchStripePlansAction, FetchUserSubscriptionDataAction } from '../redux/actions/PaymentActions';
import backIcon from "../assets/images/back_errow.svg"
import getSymbolFromCurrency from 'currency-symbol-map'

export default function SubscriptionPackages() {
    let [plansRecord, setPlansRecord] = useState('');
    let [userSubscription, setUserSubscription] = useState("");
    let [userSubscriptionStatus, setUserSubscriptionStatus] = useState("");
    let history = useHistory();
    let [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const FetchStripePlans = () => {
        dispatch(FetchStripePlansAction());
    };

    const FetchUserSubscriptionData = () => {
        dispatch(FetchUserSubscriptionDataAction());
    };

    useEffect(() => {
        setLoading(true);
        FetchUserSubscriptionData();
        FetchStripePlans();
    }, []);

    let counterPaymentPlans = useSelector((state) => state.PaymentResult.StripePlansResponse);
    let counterUserSubscription = useSelector((state) => state.PaymentResult.UserSubscriptionResponse);
    // Fetch APIs Response Data
    useEffect(() => {
        setLoading(true)
        if (counterUserSubscription.status) {
            if (counterUserSubscription.data !== null) {
                setUserSubscription(counterUserSubscription.data.client_plan);
                setUserSubscriptionStatus(counterUserSubscription.data.client_status);
            }
            else {
                setUserSubscription('');
                setUserSubscriptionStatus('');
            }
        }
        else {
            setUserSubscription('');
        }
        if (counterPaymentPlans.status) {
            setLoading(false);
            setPlansRecord(counterPaymentPlans.data)
        }
        else {
            setPlansRecord('');
        }
    }, [counterPaymentPlans])

    return (
        <section className="subscriptionSection">
            <div className="container">
                <div className="headings">
                    <div className="dataOption">
                        <div className="homeDiv mainResults">
                            <img src={homeLogo} alt="Resource Unavailable" />
                            <img src={rightArrow} alt="Resource Unavailable" />
                            <h6>Packages</h6>
                        </div>
                        <button className='backButton' onClick={() => history.goBack()}><i className="fa fa-arrow-circle-left" />Go Back</button>
                    </div>
                    <div className="subscription-package-text">
                        <h2>Choose Your Subscription Package</h2>
                    </div>
                </div>
                {loading ? <div className="loader"></div> :
                    <div className="row justify-content-center">
                        {plansRecord.length > 0 && plansRecord.map((planRecord, index) => (
                            <div className="col-md-4 col-lg-3 pe-4 mobileResponsive" key={index}>
                                <div className={userSubscription === planRecord.price_id && userSubscriptionStatus === 'active' ? "subscribed-package" : "subCard"}>
                                    <div className="subCardHeader">
                                        <h2>{planRecord.product_name}</h2>
                                        <p>{planRecord.plan_amount === 0 ? 'Free' : getSymbolFromCurrency(planRecord.plan_currency) + planRecord.plan_amount}<span style={{ textTransform: 'capitalize' }}>{planRecord.plan_amount === 0 ? '' : ' / ' + planRecord.interval}</span></p>
                                    </div>
                                    <ul className="list-unstyled mb-4 price-list">
                                        {planRecord.product_description.split('. ').map((line, i) => (
                                            <li className="mb-3" key={i}><i className="fa fa-check"></i> {line}</li>
                                        ))}
                                    </ul>
                                    <div className="startedBtnDiv">
                                        {userSubscription === planRecord.price_id && userSubscriptionStatus === 'active' ? <button disabled>Subscribed</button> : <Link to={
                                            {
                                                pathname: pathNames.Checkout,
                                                state: planRecord.price_id
                                            }
                                        }><button>Subscribe</button></Link>}
                                    </div>
                                </div>
                            </div>
                        ))}
                        {((plansRecord.length === 0 || plansRecord.length <= 0) && <span className='error'>No Records Available</span>
                        )}
                    </div>
                }
                <Link className="goBack" to={pathNames.mainSearch}>
                    <span><img className="eyeIcon" src={backIcon} alt="eyeIcon" /></span>
                    <span>Back to Main Search</span>
                </Link>
            </div>
        </section >

    )
}