import React, { useState, useEffect } from "react";
import { useHistory, Link } from 'react-router-dom';
import homeLogo from "../assets/images/home_icon.svg"
import rightArrow from "../assets/images/right_arrow.svg"
import pathNames from "../router/RoutePath";
import { useDispatch, useSelector } from 'react-redux';
import { FetchCountiesAction } from '../redux/actions/KnackActions';
import backIcon from "../assets/images/back_errow.svg"

export default function Counties() {
    let [constraints, setConstraints] = useState({
        page: "1",
        limit: "50",
        order: "asc",
        filters: ""
    });
    let history = useHistory();
    let [countiesRecord, setCountiesRecord] = useState('');
    let [countiesFilter, setCountiesFilter] = useState('');
    let [currentPage, setCurrentPage] = useState('');
    let [totalPages, setTotalPages] = useState('');
    let [totalRecords, setTotalRecords] = useState('');
    let [toRecords, setToRecords] = useState('');
    let [fromRecords, setFromRecords] = useState('');
    let [nextPage, setNextPage] = useState('');
    let [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const FetchCountiesData = (obj) => {
        // setCountiesRecord();
        dispatch(FetchCountiesAction(obj));
    };

    const handleFieldChange = e => {
        setLoading(true);
        setCountiesFilter(e.target.value);
    }

    const nextPageData = (e) => {
        e.preventDefault();
        setLoading(true);
        setNextPage(currentPage + 1);
    };

    const previousPageData = (e) => {
        e.preventDefault();
        setLoading(true);
        setNextPage(currentPage - 1);
    };

    useEffect(() => {
        setLoading(true);
        FetchCountiesData(constraints);
    }, []);

    let counterCounties = useSelector((state) => state);
    // Fetch Knack APIs Data
    useEffect(() => {
        if (counterCounties.knackResult.knackResponse !== '') {
            let status = counterCounties.knackResult.knackResponse.status;
            if (status === true) {
                setCountiesRecord(counterCounties.knackResult.knackResponse.data.data);
                setCurrentPage(counterCounties.knackResult.knackResponse.data.current_page);
                setTotalPages(counterCounties.knackResult.knackResponse.data.last_page);
                setTotalRecords(counterCounties.knackResult.knackResponse.data.total);
                setToRecords(counterCounties.knackResult.knackResponse.data.to);
                setFromRecords(counterCounties.knackResult.knackResponse.data.from);
                setLoading(false)
            }
            else if (status === false) {
                console.log(counterCounties.userAuth.authResponse.message);
            }
        }
        else {
            return
        }
    }, [counterCounties])

    useEffect(() => {
        if (nextPage && nextPage >= 1) {
            setLoading(true);
            let obj = { ...constraints };
            obj.page = nextPage;
            obj.limit = '50';
            obj.order = 'asc';
            obj.filters = '';
            setConstraints({ ...obj });
            FetchCountiesData(obj);
        }
    }, [nextPage])


    useEffect(() => {
        if (countiesFilter && countiesFilter.length > 1 && (countiesFilter !== null || countiesFilter !== '')) {
            let filterObj = { ...constraints };
            filterObj.page = '1';
            filterObj.limit = '50';
            filterObj.order = 'asc';
            filterObj.filters = countiesFilter;
            setConstraints({ ...filterObj });
            FetchCountiesData(filterObj);
        }
        else {
            let filterObj = { ...constraints };
            filterObj.page = '1';
            filterObj.limit = '50';
            filterObj.order = 'asc';
            filterObj.filters = '';
            setConstraints({ ...filterObj });
            FetchCountiesData(filterObj);
        }
    }, [countiesFilter])

    return (
        <section>
            <div className="searchPart">
                <div className="searchBox extraBox">
                    <h5>Counties</h5>
                    <p>Enter County Name or State Abbreviation</p>
                    <div className="displayDiv">
                        <div className="pseudo-search mt-3">
                            <input type="text" placeholder="Search" minLength={2} onChange={handleFieldChange} />
                            <button className="fa fa-search" type="submit"></button>
                        </div>
                    </div>
                    <div className="searchLink">
                        <span>
                            or Search by
                            <Link to={pathNames.states}>States,</Link>
                            <Link to={pathNames.CountiesPage}>Counties,</Link>
                            <Link to={pathNames.jurisdictions}>Jurisdictions</Link>
                        </span>
                    </div>
                </div>
            </div>
            <div className="resultSearch">
                <div className="dataOption">
                    <div className="homeDiv mainResults">
                        <img src={homeLogo} alt="" />
                        <img src={rightArrow} alt="" />
                        <h6>Counties</h6>
                    </div>
                    <button className='backButton' onClick={() => history.goBack()}><i className="fa fa-arrow-circle-left" />Go Back</button>
                </div>
                <h5>Counties Data</h5>
                <div>
                    {loading ? <div className="loader"></div> :
                        <div>
                            <table responsive={"responsive"} className="table table-bordered">
                                <thead className="table-head">
                                    <tr>
                                        <th scope="col">State</th>
                                        <th scope="col">County Name</th>
                                    </tr>
                                </thead>
                                <tbody className="placeTitle">
                                    {countiesRecord.length > 0 && countiesRecord.map((countyRecord, index) => (
                                        <tr data-index={index} key={index}>
                                            <td><Link to={
                                                {
                                                    pathname: pathNames.StateDetail,
                                                    state: countyRecord.state_id
                                                }
                                            } className="placeName">{countyRecord.state_name}</Link></td>
                                            <td><Link to={
                                                {
                                                    pathname: pathNames.CountyDetails,
                                                    state: countyRecord.county_id
                                                }
                                            } className="placeName">{countyRecord.county_name}</Link></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="col-sm-12 mainPagination">
                                <div className='paginationBar'>
                                    <span className="paginationButtons">
                                        {((currentPage > 1) && <button className="fa fa-chevron-left paginationArrow" type="button" onClick={previousPageData}></button>)}
                                        <button className='paginationNumber'>{currentPage}</button>
                                        {((currentPage < totalPages) && <button className="fa fa-chevron-right paginationArrow" type="button" onClick={nextPageData}></button>
                                        )}
                                    </span>
                                    <span>of {totalPages} pages</span>
                                </div>
                                <p className='m-0'>Total Records: {totalRecords} Showing  {fromRecords}-{toRecords} of {totalRecords}</p>
                            </div>
                            {((countiesRecord.length === 0 || countiesRecord.length <= 0) && <span className='error'>No Records Available</span>
                            )}
                        </div>
                    }
                </div>
                <Link className="goBack" to={pathNames.states}>
                    <span><img className="eyeIcon" src={backIcon} alt="eyeIcon" /></span>
                    <span>Back to States</span>
                </Link>
            </div>
        </section>
    )
}