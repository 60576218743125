import React, { useEffect, useState } from "react";
import ModalPopup from "../components/ModalPopup";
import pathNames from "../router/RoutePath";
import { useDispatch, useSelector } from 'react-redux';
import { FetchUserSubscriptionDataAction } from '../redux/actions/PaymentActions';


export default function Subscribed() {

    let [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const FetchUserSubscriptionData = () => {
        dispatch(FetchUserSubscriptionDataAction());
    };

    useEffect(() => {
        FetchUserSubscriptionData();
    }, []);

    let counterUserSubscription = useSelector((state) => state.PaymentResult.UserSubscriptionResponse);

    useEffect(() => {
        setLoading(true)
        if (counterUserSubscription.status) {
            if (counterUserSubscription.data !== null) {
                var userDetails = counterUserSubscription.data;
                localStorage.removeItem('user-subscription-details');
                localStorage.setItem('user-subscription-details', JSON.stringify(userDetails));
                setLoading(false);
            }
        }
    }, [counterUserSubscription])
    return (
        <section className="loginSection">
            <div className="container-fluid g-0">
                <div className="row g-0">
                    <div className="col-md-12" >
                        <div className="mainLogin">
                            {loading ?
                                <div className="loader"></div> :
                                <div className="mainContent">
                                    <ModalPopup Message='Subscription Successful' LinkMessage="Click to Continue" LinkAnchor={pathNames.mainSearch} />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}