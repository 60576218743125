import "./assets/css/main.css"
import "./assets/css/loader.css"
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppRoutes from "./router";

function App() {
  return (
    <>
      <AppRoutes />
    </>
  );
}

export default App;
