// Auth API Action Type Start
export const RESTART_AUTH_RESPONSE = 'RESTART_AUTH_RESPONSE';
export const LOADING = 'LOADING';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const CODE_ERROR = 'CODE_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';
export const LOAD_PROFILE_SUCCESS = 'LOAD_PROFILE_SUCCESS';
export const LOAD_PROFILE_ERROR = 'LOAD_PROFILE_ERROR';
export const FORGOT_PASSWORD_LINK_SUCCESS = 'FORGOT_PASSWORD_LINK_SUCCESS';
export const FORGOT_PASSWORD_LINK_ERROR = 'FORGOT_PASSWORD_LINK_ERROR';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR';

export const USER_VERIFICATION_LINK_SUCCESS = 'USER_VERIFICATION_LINK_SUCCESS';
export const USER_VERIFICATION_LINK_ERROR = 'USER_VERIFICATION_LINK_ERROR';

export const PROFILE_FETCH_SUCCESS = 'PROFILE_FETCH_SUCCESS';
export const PROFILE_FETCH_ERROR = 'PROFILE_FETCH_ERROR';

export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_ERROR = 'PROFILE_UPDATE_ERROR';
// Auth API Action Type End

// Knack Api Action Types

// States
export const STATES_FETCH_SUCCESS = 'STATES_FETCH_SUCCESS';
export const STATES_FETCH_ERROR = 'STATES_FETCH_ERROR';

// Single State
export const STATE_FETCH_SUCCESS = 'STATE_FETCH_SUCCESS';
export const STATE_FETCH_ERROR = 'STATE_FETCH_ERROR';

// State Counties
export const STATE_COUNTIES_FETCH_SUCCESS = 'STATE_COUNTIES_FETCH_SUCCESS';
export const STATE_COUNTIES_FETCH_ERROR = 'STATE_COUNTIES_FETCH_ERROR';

// Counties
export const COUNTY_FETCH_SUCCESS = 'COUNTY_FETCH_SUCCESS';
export const COUNTY_FETCH_ERROR = 'COUNTY_FETCH_ERROR';

export const COUNTY_JURISDICTIONS_FETCH_SUCCESS = 'COUNTY_JURISDICTIONS_FETCH_SUCCESS';
export const COUNTY_JURISDICTIONS_FETCH_ERROR = 'COUNTY_JURISDICTIONS_FETCH_ERROR';

export const COUNTIES_FETCH_SUCCESS = 'COUNTIES_FETCH_SUCCESS';
export const COUNTIES_FETCH_ERROR = 'COUNTIES_FETCH_ERROR';

// Jurisdictions
export const JURISDICTIONS_FETCH_SUCCESS = 'JURISDICTIONS_FETCH_SUCCESS';
export const JURISDICTIONS_FETCH_ERROR = 'JURISDICTIONS_FETCH_ERROR';

// Single Jurisdiction
export const JURISDICTION_FETCH_SUCCESS = 'JURISDICTION_FETCH_SUCCESS';
export const JURISDICTION_FETCH_ERROR = 'JURISDICTION_FETCH_ERROR';

// Single Jurisdiction Contacts
export const JURISDICTION_CONTACTS_FETCH_SUCCESS = 'JURISDICTION_CONTACTS_FETCH_SUCCESS';
export const JURISDICTION_CONTACTS_FETCH_ERROR = 'JURISDICTION_CONTACTS_FETCH_ERROR';

// Single Jurisdiction Submittal Requirements
export const JURISDICTION_SUBMITTAL_REQUIREMENTS_FETCH_SUCCESS = 'JURISDICTION_SUBMITTAL_REQUIREMENTS_FETCH_SUCCESS';
export const JURISDICTION_SUBMITTAL_REQUIREMENTS_FETCH_ERROR = 'JURISDICTION_SUBMITTAL_REQUIREMENTS_FETCH_ERROR';

// Search Results
export const SEARCH_RESULTS_FETCH_SUCCESS = 'SEARCH_RESULTS_FETCH_SUCCESS';
export const SEARCH_RESULTS_FETCH_ERROR = 'SEARCH_RESULTS_FETCH_ERROR';

// Single Submittal Requirement
export const SUBMITTAL_REQUIREMENT_FETCH_SUCCESS = 'SUBMITTAL_REQUIREMENT_FETCH_SUCCESS';
export const SUBMITTAL_REQUIREMENT_FETCH_ERROR = 'SUBMITTAL_REQUIREMENT_FETCH_ERROR';

// Single Contacts
export const CONTACT_FETCH_SUCCESS = 'CONTACT_FETCH_SUCCESS';
export const CONTACT_FETCH_ERROR = 'CONTACT_FETCH_ERROR';


//Stripe Plans 
export const STRIPE_PLANS_FETCH_SUCCESS = 'STRIPE_PLANS_FETCH_SUCCESS';
export const STRIPE_PLANS_FETCH_ERROR = 'STRIPE_PLANS_FETCH_ERROR';

//User Subscription Fetch 
export const USER_SUBSCRIPTION_FETCH_SUCCESS = 'USER_SUBSCRIPTION_FETCH_SUCCESS';
export const USER_SUBSCRIPTION_FETCH_ERROR = 'USER_SUBSCRIPTION_FETCH_ERROR';

//Stripe Plan
export const STRIPE_PLAN_FETCH_SUCCESS = 'STRIPE_PLAN_FETCH_SUCCESS';
export const STRIPE_PLAN_FETCH_ERROR = 'STRIPE_PLAN_FETCH_ERROR';

export const STRIPE_PAYMENT_INTENT_CREATE_SUCCESS = 'STRIPE_PAYMENT_INTENT_CREATE_SUCCESS';
export const STRIPE_PAYMENT_INTENT_CREATE_ERROR = 'STRIPE_PAYMENT_INTENT_CREATE_ERROR';

export const USER_SUBSCRIPTION_UPDATE_SUCCESS = 'USER_SUBSCRIPTION_UPDATE_SUCCESS';
export const USER_SUBSCRIPTION_UPDATE_ERROR = 'USER_SUBSCRIPTION_UPDATE_ERROR';

export const EXPORT_PDF_SUCCESS = 'EXPORT_PDF_SUCCESS';
export const EXPORT_PDF_ERROR = 'EXPORT_PDF_ERROR';

export const FETCH_SEARCH_COUNT_SUCCESS = 'FETCH_SEARCH_COUNT_SUCCESS';
export const FETCH_SEARCH_COUNT_ERROR = 'FETCH_SEARCH_COUNT_ERROR';
