import pathNames from "../RoutePath";
import MainSearch from "../../views/MainSearch";
import SubscriptionPackages from "../../views/SubscriptionPackages";
import SearchResult from "../../views/SearchResult";
import Jurisdictions from "../../views/Jurisdictions";
import ProfileInfo from "../../views/ProfileInfo";
import States from "../../views/States";
import CountiesPage from "../../views/Counties";
import JurisdictionDetails from "../../views/JurisdictionDetails";
import SubmitDetail from "../../views/SubmitDetails";
import StateDetail from "../../views/StateDetail";
import CountyDetail from "../../views/CountyDetail"
import ContactDetail from "../../views/ContactDetails";
import Checkout from "../../views/Checkout";
import Subscribed from "../../views/Subscribed";
import ExportDetail from "../../views/ExportDetails";
import SubscriptionLimit from "../../views/SubscriptionLimit";


const protectedRoutes = [
    { path: pathNames.mainSearch, component: MainSearch },
    { path: pathNames.subscriptionPackages, component: SubscriptionPackages },
    { path: pathNames.searchResult, component: SearchResult },
    { path: pathNames.jurisdictions, component: Jurisdictions },
    { path: pathNames.ProfileInfo, component: ProfileInfo },
    { path: pathNames.states, component: States, },
    { path: pathNames.StateDetail, component: StateDetail, },
    { path: pathNames.CountiesPage, component: CountiesPage },
    { path: pathNames.CountyDetails, component: CountyDetail, },
    { path: pathNames.JurisdictionDetails, component: JurisdictionDetails },
    { path: pathNames.SubmitDetail, component: SubmitDetail },
    { path: pathNames.ContactDetail, component: ContactDetail },
    { path: pathNames.Checkout, component: Checkout },
    { path: pathNames.Subscribed, component: Subscribed },
    { path: pathNames.ExportDetails, component: ExportDetail },
    { path: pathNames.SubscriptionsLimit, component: SubscriptionLimit },

]

export default protectedRoutes;