import * as ActionTypes from '../ActionTypes';
const initState = {
    knackResponse: "",
    JurisdictionDetails: "",
    JurisdictionContactsDetails: "",
    JurisdictionSearchResults: "",
    JurisdictionSubmittalRequirementDetails: "",
    SubmittalRequirementData: "",
    ContactData: "",
    StateData: "",
    StateCountiesData: "",
    CountyData: "",
    CountyJurisdictionsData: "",
    ExportPdf: ""
};
const KnackReducer = (state = initState, action) => {
    switch (action.type) {
        case ActionTypes.RESTART_AUTH_RESPONSE:
            return {
                ...state,
                knackResponse: "",
            };
        case ActionTypes.LOADING:
            return {
                ...state,
                knackResponse: "loading...",
            };
        case ActionTypes.STATES_FETCH_SUCCESS:
            return {
                ...state,
                knackResponse: action.res,
            };
        case ActionTypes.STATES_FETCH_ERROR:
            return {
                ...state,
                knackResponse: action.res,
            };
        case ActionTypes.STATE_FETCH_SUCCESS:
            return {
                ...state,
                StateData: action.res,
            };
        case ActionTypes.STATE_FETCH_ERROR:
            return {
                ...state,
                StateData: action.res,
            };
        case ActionTypes.STATE_COUNTIES_FETCH_SUCCESS:
            return {
                ...state,
                StateCountiesData: action.res,
            };
        case ActionTypes.STATE_COUNTIES_FETCH_ERROR:
            return {
                ...state,
                StateCountiesData: action.res,
            };
        case ActionTypes.COUNTIES_FETCH_SUCCESS:
            return {
                ...state,
                knackResponse: action.res,
            };
        case ActionTypes.COUNTIES_FETCH_ERROR:
            return {
                ...state,
                knackResponse: action.res,
            };
        case ActionTypes.COUNTY_FETCH_SUCCESS:
            return {
                ...state,
                CountyData: action.res,
            };
        case ActionTypes.COUNTY_FETCH_ERROR:
            return {
                ...state,
                CountyData: action.res,
            };
        case ActionTypes.COUNTY_JURISDICTIONS_FETCH_SUCCESS:
            return {
                ...state,
                CountyJurisdictionsData: action.res,
            };
        case ActionTypes.COUNTY_JURISDICTIONS_FETCH_ERROR:
            return {
                ...state,
                CountyJurisdictionsData: action.res,
            };
        case ActionTypes.JURISDICTIONS_FETCH_SUCCESS:
            return {
                ...state,
                knackResponse: action.res,
            };
        case ActionTypes.JURISDICTIONS_FETCH_ERROR:
            return {
                ...state,
                knackResponse: action.res,
            };
        case ActionTypes.JURISDICTION_FETCH_SUCCESS:
            return {
                ...state,
                JurisdictionDetails: action.res,
            };
        case ActionTypes.JURISDICTION_FETCH_ERROR:
            return {
                ...state,
                JurisdictionDetails: action.res,
            };
        case ActionTypes.JURISDICTION_CONTACTS_FETCH_SUCCESS:
            return {
                ...state,
                JurisdictionContactsDetails: action.res,
            };
        case ActionTypes.JURISDICTION_CONTACTS_FETCH_ERROR:
            return {
                ...state,
                JurisdictionContactsDetails: action.res,
            };
        case ActionTypes.JURISDICTION_SUBMITTAL_REQUIREMENTS_FETCH_SUCCESS:
            return {
                ...state,
                JurisdictionSubmittalRequirementDetails: action.res,
            };
        case ActionTypes.JURISDICTION_SUBMITTAL_REQUIREMENTS_FETCH_ERROR:
            return {
                ...state,
                JurisdictionSubmittalRequirementDetails: action.res,
            };
        case ActionTypes.SEARCH_RESULTS_FETCH_SUCCESS:
            return {
                ...state,
                JurisdictionSearchResults: action.res,
            };
        case ActionTypes.SEARCH_RESULTS_FETCH_ERROR:
            return {
                ...state,
                JurisdictionSearchResults: action.res,
            };
        case ActionTypes.SUBMITTAL_REQUIREMENT_FETCH_SUCCESS:
            return {
                ...state,
                SubmittalRequirementData: action.res,
            };
        case ActionTypes.SUBMITTAL_REQUIREMENT_FETCH_ERROR:
            return {
                ...state,
                SubmittalRequirementData: action.res,
            };
        case ActionTypes.CONTACT_FETCH_SUCCESS:
            return {
                ...state,
                ContactData: action.res,
            };
        case ActionTypes.CONTACT_FETCH_ERROR:
            return {
                ...state,
                ContactData: action.res,
            };
        case ActionTypes.EXPORT_PDF_SUCCESS:
            return {
                ...state,
                ExportPdf: action.res,
            };
        case ActionTypes.EXPORT_PDF_ERROR:
            return {
                ...state,
                ExportPdf: action.res,
            };
        case ActionTypes.CODE_ERROR:
            return {
                ...state,
                knackResponse:
                    "There seems to be a problem, please refresh your browser",
            };
        default:
            return state;
    }
};
export default KnackReducer;