import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import KnackReducer from './KnackReducer';
import PaymentReducer from './PaymentReducer';

const RootReducer = combineReducers({
    userAuth: AuthReducer,
    knackResult: KnackReducer,
    PaymentResult: PaymentReducer,
});
export default RootReducer;