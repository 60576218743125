import HttpService from './HttpService';
import {
    fetchStripePlansData,
    fetchUserSubscriptionData,
    fetchStripePlanData,
    createPaymentIntent,
    updateSubscription
} from "../constant";


// Stripe Plans Service
export const FetchStripePlansService = () => {
    const http = new HttpService();
    const tokenId = "user-token";
    return http.getData(fetchStripePlansData, tokenId).then((data) => {
        return data;
    }).catch((error) => {
        return error;
    })
}

// Stripe Plan Service
export const FetchStripePlanService = (constraints) => {
    const http = new HttpService();
    const tokenId = "user-token";
    let params = '?price=' + constraints;
    return http.getData(fetchStripePlanData + params, tokenId).then((data) => {
        return data;
    }).catch((error) => {
        return error;
    })
}

export const CreatePaymentIntentService = async (constraints) => {
    const http = new HttpService();
    const tokenId = "user-token";
    let params = '?price=' + constraints;
    return await http.getData(createPaymentIntent + params, tokenId).then((data) => {
        return data;
    }).catch((error) => {
        return error;
    })
}

// Fetch User Subscriptions Service
export const FetchUserSubscriptionDataService = async () => {
    const http = new HttpService();
    const tokenId = "user-token";
    return await http.getData(fetchUserSubscriptionData, tokenId).then((data) => {
        return data;
    }).catch((error) => {
        console.log(error);
        return error;;
    })
}

// Fetch User Subscriptions Service
export const UpdateUserSubscriptionService = async (constraints) => {
    const http = new HttpService();
    const tokenId = "user-token";
    let subs_id = '?subs_id=' + constraints;
    return await http.getData(updateSubscription + subs_id, tokenId).then((data) => {
        return data;
    }).catch((error) => {
        console.log(error);
        return error;
    })
}