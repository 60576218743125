import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from 'react-router-dom';
import homeLogo from "../assets/images/home_icon.svg"
import rightArrow from "../assets/images/right_arrow.svg"
import backIcon from "../assets/images/back_errow.svg"
import pathNames from "../router/RoutePath";
import {
    FetchStateAction,
    FetchStateCountiesAction
} from '../redux/actions/KnackActions';
import { useDispatch, useSelector } from 'react-redux';

export default function StateDetail() {
    let location = useLocation();
    let history = useHistory();
    let stateId = location.state;
    let [constraints, setConstraints] = useState({
        page: "1",
        limit: "50",
        order: "asc",
        state: stateId,
        filters: ""
    });

    let [loading, setLoading] = useState(false);
    let [loadingStateCounties, setLoadingStateCounties] = useState(false);
    let [stateData, setStateData] = useState('');
    let [stateCountiesRecord, setStateCountiesRecord] = useState('');
    let [countiesFilter, setCountiesFilter] = useState('');
    let [currentStateCountiesPage, setCurrentStateCountiesPage] = useState('');
    let [totalStateCountiesPages, setTotalStateCountiesPages] = useState('');
    let [totalStateCountiesRecords, setStateCountiesRecords] = useState('');
    let [toRecords, setToRecords] = useState('');
    let [fromRecords, setFromRecords] = useState('');
    let [nextPage, setNextPage] = useState('');
    const dispatch = useDispatch();
    const FetchStateData = (id) => {
        dispatch(FetchStateAction(id));
    };

    const FetchStateCountiesData = (obj) => {
        dispatch(FetchStateCountiesAction(obj));
    }

    useEffect(() => {
        setLoading(true);
        setLoadingStateCounties(true)
        FetchStateData(stateId);
        FetchStateCountiesData(constraints);
    }, []);

    let counterState = useSelector((state) => state.knackResult.StateData);
    let counterStateCounties = useSelector((state) => state.knackResult.StateCountiesData);

    // Fetch Knack APIs Data
    useEffect(() => {
        if (counterState !== '') {
            let status = counterState.status;
            if (status === true) {
                let data = counterState.data;
                setStateData(data);
                setLoading(false);
            }
            else if (status === false) {
                console.log(counterState.message);
            }
        }
    }, [counterState])

    // Fetch Knack APIs Data
    useEffect(() => {
        if (counterStateCounties !== '') {
            let status = counterStateCounties.status;
            if (status === true) {
                setStateCountiesRecord(counterStateCounties.data.data);
                setCurrentStateCountiesPage(counterStateCounties.data.current_page);
                setTotalStateCountiesPages(counterStateCounties.data.last_page);
                setStateCountiesRecords(counterStateCounties.data.total);
                setToRecords(counterStateCounties.data.to);
                setFromRecords(counterStateCounties.data.from);
                setLoadingStateCounties(false);
            }
            else if (status === false) {
                console.log(counterStateCounties.message);
            }
        }
        else {
            return
        }
    }, [counterStateCounties])

    const nextPageData = (e) => {
        e.preventDefault();
        setLoadingStateCounties(true);
        setNextPage(currentStateCountiesPage + 1);
    };

    const previousPageData = (e) => {
        e.preventDefault();
        setLoadingStateCounties(true);
        setNextPage(currentStateCountiesPage - 1);
    };

    useEffect(() => {
        if (nextPage && nextPage >= 1) {
            setLoadingStateCounties(true);
            let obj = { ...constraints };
            obj.page = nextPage;
            obj.limit = '50';
            obj.order = 'asc';
            obj.state = stateId;
            obj.filters = '';
            setConstraints({ ...obj });
            FetchStateCountiesData(obj);
        }
    }, [nextPage])


    return (
        <section>
            <div className="searchPart">
                <div className="searchBox mainDetail">
                    <div>
                        <h5>State Details</h5>
                    </div>
                </div>
            </div>
            <div className="JurisdictionDetail">
                <div className="dataOption">
                    <div className="homeDiv itemDiv">
                        <img src={homeLogo} alt="" />
                        <img src={rightArrow} alt="" />
                        <Link className="placeName" to={pathNames.states}> <h6>States</h6></Link>
                        <img src={rightArrow} alt="" />
                        <h6>States Details</h6>
                    </div>
                    <button className='backButton' onClick={() => history.goBack()}><i className="fa fa-arrow-circle-left" />Go Back</button>
                </div>
                <br />
                {loading ? <div className="loader"></div> :
                    <div id="JurisdictionMainData">
                        <h6>State Details</h6>
                        <div className="row">
                            <div className="col-md-6">
                                <label className="formLabel" htmlFor="formGroupExampleInput">State</label>
                                <input style={{ backgroundColor: '#edf4ef' }} type="text" className="form-control" value={stateData.state_abb} readOnly />
                            </div>
                            <div className="col-md-6">
                                <label className="formLabel" htmlFor="formGroupExampleInput">State Name</label>
                                <input style={{ backgroundColor: '#edf4ef' }} type="text" className="form-control" value={stateData.state_name} readOnly />
                            </div>
                        </div>
                    </div>
                }
                <br />
                <h6>{stateData.state_name}'s Counties Data</h6>
                {loadingStateCounties ? <div className="loader"></div> :
                    <div className="row">
                        <div className="col-md-12">
                            <table responsive={"responsive"} className="table table-bordered">
                                <thead className="table-head">
                                    <tr>
                                        <th scope="col">State</th>
                                        <th scope="col">County Name</th>
                                    </tr>
                                </thead>
                                <tbody className="placeTitle">
                                    {stateCountiesRecord.length > 0 && stateCountiesRecord.map((countyRecord, index) => (
                                        <tr data-index={index} key={index}>
                                            <td>{countyRecord.state_name}</td>
                                            <td><Link to={
                                                {
                                                    pathname: pathNames.CountyDetails,
                                                    state: countyRecord.county_id
                                                }
                                            } className="placeName">{countyRecord.county_name}</Link></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="col-md-12 mainPagination">
                                <div className='paginationBar'>
                                    <span className="paginationButtons">
                                        {((currentStateCountiesPage > 1) && <button className="fa fa-chevron-left paginationArrow" type="button" onClick={previousPageData}></button>)}
                                        <button className='paginationNumber'>{currentStateCountiesPage}</button>
                                        {((currentStateCountiesPage < totalStateCountiesRecords) && <button className="fa fa-chevron-right paginationArrow" type="button" onClick={nextPageData}></button>
                                        )}
                                    </span>
                                    {/* <span>of {totalStateCountiesRecords} pages</span> */}
                                </div>
                                <p className='m-0'>Total Records: {totalStateCountiesRecords} Showing  {fromRecords}-{toRecords} of {totalStateCountiesRecords}</p>
                            </div>
                            {((stateCountiesRecord.length === 0 || stateCountiesRecord.length <= 0) && <span className='error'>No Records Available</span>
                            )}
                        </div>
                    </div>
                }
                <Link className="goBack" to={pathNames.states}>
                    <span><img className="eyeIcon" src={backIcon} alt="eyeIcon" /></span>
                    <span>Back to States</span>
                </Link>
            </div>
        </section>
    )
}