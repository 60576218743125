import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from 'react-router-dom';
import homeLogo from "../assets/images/home_icon.svg"
import rightArrow from "../assets/images/right_arrow.svg"
import eyeIcon from "../assets/images/eye_icon.svg"
import backIcon from "../assets/images/back_errow.svg"
import pathNames from "../router/RoutePath";
import {
    FetchJurisdictionAction,
    FetchJurisdictionContactsAction,
    FetchCountySubmittalRequirementsAction
} from '../redux/actions/KnackActions';
import { useDispatch, useSelector } from 'react-redux';

export default function JurisdictionDetails() {
    let location = useLocation();
    let history = useHistory();
    let jurisdictionId = location.state[0].jurisdiction_id;
    let countyId = location.state[1].county_id;
    let [loading, setLoading] = useState(false);
    let [loadingContacts, setLoadingContacts] = useState(false);
    let [loadingSubmittalRequirements, setLoadingSubmittalRequirements] = useState(false);
    let [jurisdictionData, setJurisdictionData] = useState('');
    let [jurisdictionContactsData, setJurisdictionContactsData] = useState('');
    let [countySubmittalRequirementsData, setCountySubmittalRequirements] = useState('');

    let [currentContactsPage, setCurrentContactsPage] = useState('');
    let [totalContactsPages, setTotalContactsPages] = useState('');
    let [totalContactsRecords, setTotalContactsRecords] = useState('');

    let [currentSubmittalRequirementsPage, setCurrentSubmittalRequirementsPage] = useState('');
    let [totalSubmittalRequirementsPages, setTotalSubmittalRequirementsPages] = useState('');
    let [totalSubmittalRequirementsRecords, setTotalSubmittalRequirementsRecords] = useState('');

    let [nextContactsPage, setNextContactsPage] = useState('');
    const dispatch = useDispatch();

    const FetchJurisdictionData = (id) => {
        dispatch(FetchJurisdictionAction(id));
    };

    const FetchJurisdictionContactsData = (id) => {
        dispatch(FetchJurisdictionContactsAction(id));
    };

    const FetchCountySubmittalRequirementsData = (id) => {
        dispatch(FetchCountySubmittalRequirementsAction(id));
    };

    useEffect(() => {
        setLoading(true);
        setLoadingContacts(true);
        setLoadingSubmittalRequirements(true);
        FetchJurisdictionData(jurisdictionId);
        FetchJurisdictionContactsData(jurisdictionId);
        FetchCountySubmittalRequirementsData(countyId);
    }, []);

    let counterJurisdiction = useSelector((state) => state.knackResult.JurisdictionDetails);
    let counterJurisdictionContacts = useSelector((state) => state.knackResult.JurisdictionContactsDetails);
    let counterCountySubmittalRequirements = useSelector((state) => state.knackResult.JurisdictionSubmittalRequirementDetails);

    // Fetch Knack APIs Data
    useEffect(() => {
        if (counterJurisdiction !== '') {
            let status = counterJurisdiction.status;
            if (status === true) {
                let data = counterJurisdiction.data;
                setJurisdictionData(data);
                setLoading(false);
            }
            else if (status === false) {
                console.log(counterJurisdiction.message);
            }
        }
    }, [counterJurisdiction])

    // Fetch Knack APIs Data
    useEffect(() => {
        if (counterJurisdictionContacts !== '') {
            let status = counterJurisdictionContacts.status;
            if (status === true) {
                let dataContacts = counterJurisdictionContacts.data.records;
                setJurisdictionContactsData(dataContacts);
                setCurrentContactsPage(counterJurisdictionContacts.data.current_page);
                setTotalContactsPages(counterJurisdictionContacts.data.total_pages);
                setTotalContactsRecords(counterJurisdictionContacts.data.total_records);
                setLoadingContacts(false);
            }
            else if (status === false) {
                console.log(counterJurisdictionContacts.message);
            }
        }
    }, [counterJurisdictionContacts])

    // Fetch Knack APIs Data
    useEffect(() => {
        if (counterCountySubmittalRequirements !== '') {
            let status = counterCountySubmittalRequirements.status;
            if (status === true) {
                let sub_data = counterCountySubmittalRequirements.data.records;
                setCountySubmittalRequirements(sub_data);
                setCurrentSubmittalRequirementsPage(counterCountySubmittalRequirements.data.current_page);
                setTotalSubmittalRequirementsPages(counterCountySubmittalRequirements.data.total_pages);
                setTotalSubmittalRequirementsRecords(counterCountySubmittalRequirements.data.total_records);
                setLoadingSubmittalRequirements(false);
            }
            else if (status === false) {
                console.log(counterCountySubmittalRequirements.message);
            }
        }
    }, [counterCountySubmittalRequirements])

    useEffect(() => {
        // console.log(jurisdictionData);
    }, [jurisdictionData])

    useEffect(() => {
        // console.log(jurisdictionData);
    }, [counterJurisdictionContacts])

    useEffect(() => {
        // console.log(jurisdictionData);
    }, [counterCountySubmittalRequirements])

    const nextPageData = (e) => {
        e.preventDefault();
        setLoadingContacts(true);
        setNextContactsPage(currentContactsPage + 1);
    };

    const previousPageData = (e) => {
        e.preventDefault();
        setLoadingContacts(true);
        setNextContactsPage(currentContactsPage - 1);
    };

    useEffect(() => {
        if (nextContactsPage && nextContactsPage >= 1) {
            setLoading(true);
            let obj = [];
            obj.page = nextContactsPage;
            obj.id = jurisdictionId;
            FetchJurisdictionContactsData(obj);
        }
    }, [nextContactsPage])
    return (
        <section>
            <div className="searchPart">
                <div className="searchBox mainDetail">
                    <div>
                        <h5>Jurisdiction Details</h5>
                    </div>
                </div>
            </div>
            <div className="JurisdictionDetail">
                <div className="dataOption">
                    <div className="homeDiv itemDiv">
                        <img src={homeLogo} alt="" />
                        <img src={rightArrow} alt="" />
                        <Link className="placeName" to={pathNames.jurisdictions}> <h6>Jurisdiction</h6></Link>
                        <img src={rightArrow} alt="" />
                        <h6>Jurisdiction Details</h6>
                    </div>
                    <button className='backButton' onClick={() => history.goBack()}><i className="fa fa-arrow-circle-left" />Go Back</button>
                </div>
                <br />
                {loading ? <div className="loader"></div> :
                    <div id="JurisdictionMainData">
                        <h6>Jurisdiction Details</h6>
                        <div className="row">
                            <div className="col-md-6">
                                <label className="formLabel" htmlFor="formGroupExampleInput">Jurisdiction ID</label>
                                <input style={{ backgroundColor: '#edf4ef' }} type="text" className="form-control" value={jurisdictionData.jurisdiction_bpo_id} readOnly />
                            </div>
                            <div className="col-md-6">
                                <label className="formLabel" htmlFor="formGroupExampleInput">Jurisdiction</label>
                                <input style={{ backgroundColor: '#edf4ef' }} type="text" className="form-control" value={jurisdictionData.jurisdiction_name} readOnly />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <label className="formLabel" htmlFor="formGroupExampleInput">State</label>
                                <span style={{ backgroundColor: '#edf4ef' }} type="text" className="form-control"> <Link className="placeName" to={
                                    {
                                        pathname: pathNames.StateDetail,
                                        state: jurisdictionData.state_id
                                    }
                                }>{jurisdictionData.state_name}</Link> </span>
                            </div>
                            <div className="col-md-6">
                                <label className="formLabel" htmlFor="formGroupExampleInput">County</label>
                                <span style={{ backgroundColor: '#edf4ef' }} type="text" className="form-control"> <Link className="placeName" to={
                                    {
                                        pathname: pathNames.CountyDetails,
                                        state: jurisdictionData.county_id
                                    }
                                } >{jurisdictionData.county_name}</Link> </span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <label className="formLabel" htmlFor="formGroupExampleInput">URL Link</label>
                                {jurisdictionData.jurisdiction_url ? <span style={{ backgroundColor: '#edf4ef' }} type="text" className="form-control"> <Link className="placeName" to={jurisdictionData.jurisdiction_url}>{jurisdictionData.jurisdiction_url}</Link></span> :
                                    <input style={{ backgroundColor: '#edf4ef' }} type="text" className="form-control" value={'No URL'} readOnly />}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-xs-12 col-lg-12">
                                <label className="formLabel" htmlFor="formGroupExampleInput">Address</label>
                                <textarea style={{ backgroundColor: '#edf4ef' }} type="text" className="form-control" value={jurisdictionData.jurisdiction_address} readOnly></textarea>
                            </div>
                        </div>
                    </div>
                }
                <br />
                <h6>Jurisdiction Contacts</h6>
                {loadingContacts ? <div className="loader"></div> :
                    <div className="row">
                        <div className="col-md-12 table-responsive-md">
                            <table responsive={"responsive"} className="table table-bordered">
                                <thead className="table-head">
                                    <tr>
                                        <th scope="col">Contact Details</th>
                                        <th scope="col">Title</th>
                                        <th scope="col">Agency</th>
                                        <th scope="col">Phone</th>
                                        <th scope="col">Email</th>
                                    </tr>
                                </thead>
                                <tbody className="placeTitle">
                                    {jurisdictionContactsData.length > 0 && jurisdictionContactsData.map((jurisdictionContactData, index) => (
                                        <tr data-index={index} key={index}>
                                            {/* StateDetail + '?' + jurisdictionContactData.state_id */}
                                            <td><Link to={
                                                {
                                                    pathname: pathNames.ContactDetail,
                                                    state: jurisdictionContactData.contact_id
                                                }
                                            } className="placeName">{jurisdictionContactData.contact_name}</Link></td>
                                            <td>{jurisdictionContactData.contact_title}</td>
                                            <td>{jurisdictionContactData.agency_name}</td>
                                            <td><a className="placeName" href={"tel:" + jurisdictionContactData.contact_phone}>{jurisdictionContactData.contact_phone}</a></td>
                                            <td><a className="placeName" href={"mailto:" + jurisdictionContactData.contact_email}>{jurisdictionContactData.contact_email}</a></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="col-md-12 mainPagination">
                                <div className='paginationBar'>
                                    <span className="paginationButtons">
                                        {/* {((currentContactsPage > 1) && <button className="fa fa-chevron-left paginationArrow" type="button" onClick={previousPageData}></button>)}
                                        <button className='paginationNumber'>{currentContactsPage}</button>
                                        {((currentContactsPage < totalContactsPages) && <button className="fa fa-chevron-right paginationArrow" type="button" onClick={nextPageData}></button>
                                        )} */}
                                    </span>
                                    {/* <span>of {totalContactsPages} pages</span> */}
                                </div>
                                <p className='m-0'>Total Records: {totalContactsRecords}</p>
                            </div>
                            {((jurisdictionContactsData.length === 0 || jurisdictionContactsData.length <= 0) && <span className='error'>No Records Available</span>
                            )}
                        </div>
                    </div>
                }
                <h6>Submittal Requirements</h6>
                {loadingSubmittalRequirements ? <div className="loader"></div> :
                    <div className="row">
                        <div className="col-md-12 table-responsive-md">
                            <table responsive={"responsive"} className="table table-bordered">
                                <thead className="table-head">
                                    <tr>
                                        <th scope="col">Permit Type</th>
                                        <th scope="col">View Details</th>
                                    </tr>
                                </thead>
                                <tbody className="placeTitle">
                                    {countySubmittalRequirementsData.length > 0 && countySubmittalRequirementsData.map((countySubmittalRequirementData, index) => (
                                        <tr data-index={index} key={index}>
                                            <td>{countySubmittalRequirementData.permit_type}</td>
                                            <td><Link className="placeName" to={
                                                {
                                                    pathname: pathNames.SubmitDetail,
                                                    state: [{ requirement_id: countySubmittalRequirementData.requirement_id }, { jurisdiction_id: jurisdictionData.jurisdiction_id }]
                                                }
                                            }> <img className="eyeIcon" src={eyeIcon} alt="eyeIcon" /> View</Link></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="col-md-12 mainPagination">
                                <div className='paginationBar'>
                                    <span className="paginationButtons">
                                    </span>
                                </div>
                                <p className='m-0'>Total Records: {totalSubmittalRequirementsRecords}</p>
                            </div>
                            {((countySubmittalRequirementsData.length === 0 || countySubmittalRequirementsData.length <= 0) && <span className='error'>No Records Available</span>
                            )}
                        </div>
                    </div>
                }
                <Link className="goBack" to={pathNames.jurisdictions}>
                    <span><img className="eyeIcon" src={backIcon} alt="eyeIcon" /></span>
                    <span>Back to Jurisdiction</span>
                </Link>
            </div >
        </section >

    )
}