import ModalPopup from "../components/ModalPopup";
import pathNames from "../router/RoutePath";


export default function SubscriptionLimit() {
    return (
        <section className="loginSection">
            <div className="container-fluid g-0">
                <div className="row g-0">
                    <div className="col-md-12" >
                        <div className="mainLogin">
                            <div className="mainContent">
                                <ModalPopup Message='Your subscription search limit for this month has been exceeded.' LinkMessage="Subscribe another Package" LinkAnchor={pathNames.subscriptionPackages} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}