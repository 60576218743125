const paths = {
    login: "/login",
    signUp: "/signup",
    subscriptionPackages: "/subscription-packages",
    forgotPassword: "/forgot-password",
    resentVerificationLink: "/resend-link",
    ResetPassword: "/reset-password",
    mainSearch: "/home",
    searchResult: "/search-result",
    jurisdictions: "/jurisdictions",
    ProfileInfo: "/profile-info",
    CountiesPage: "/counties",
    CountyDetails: "/county-details",
    states: "/states",
    StateDetail: "/state-details",
    JurisdictionDetails: "/jurisdiction-detail",
    SubmitDetail: "/submittal-details",
    ContactDetail: "/contact-details",
    Registered: "/registered",
    Checkout: "/checkout",
    Subscribed: "/subscribed",
    ExportDetails: "/export-due-diligence",
    SubscriptionsLimit: "/subscriptions-limit"
}

export default paths