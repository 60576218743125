import * as ActionTypes from '../ActionTypes';
const initState = {
    StripePlansResponse: "",
    UserSubscriptionResponse: "",
    StripePlanResponse: "",
    PaymentIntentResponse: "",
    SubscriptionUpdate: "",
};
const PaymentReducer = (state = initState, action) => {
    switch (action.type) {
        case ActionTypes.RESTART_AUTH_RESPONSE:
            return {
                ...state,
                knackResponse: "",
            };
        case ActionTypes.LOADING:
            return {
                ...state,
                knackResponse: "loading...",
            };
        case ActionTypes.STRIPE_PLANS_FETCH_SUCCESS:
            return {
                ...state,
                StripePlansResponse: action.res,
            };
        case ActionTypes.STRIPE_PLANS_FETCH_ERROR:
            return {
                ...state,
                StripePlansResponse: action.res,
            };
        case ActionTypes.STRIPE_PLAN_FETCH_SUCCESS:
            return {
                ...state,
                StripePlanResponse: action.res,
            };
        case ActionTypes.STRIPE_PLAN_FETCH_ERROR:
            return {
                ...state,
                StripePlanResponse: action.res,
            };
        case ActionTypes.STRIPE_PAYMENT_INTENT_CREATE_SUCCESS:
            return {
                ...state,
                PaymentIntentResponse: action.res,
            };
        case ActionTypes.STRIPE_PAYMENT_INTENT_CREATE_ERROR:
            return {
                ...state,
                PaymentIntentResponse: action.res,
            };
        case ActionTypes.USER_SUBSCRIPTION_FETCH_SUCCESS:
            return {
                ...state,
                UserSubscriptionResponse: action.res,
            };
        case ActionTypes.USER_SUBSCRIPTION_FETCH_ERROR:
            return {
                ...state,
                UserSubscriptionResponse: action.res,
            };
        case ActionTypes.USER_SUBSCRIPTION_UPDATE_SUCCESS:
            return {
                ...state,
                SubscriptionUpdate: action.res,
            };
        case ActionTypes.USER_SUBSCRIPTION_UPDATE_ERROR:
            return {
                ...state,
                SubscriptionUpdate: action.res,
            };
        case ActionTypes.CODE_ERROR:
            return {
                ...state,
                knackResponse:
                    "There seems to be a problem, please refresh your browser",
            };
        default:
            return state;
    }
};
export default PaymentReducer;