import React, { useEffect } from "react";
import { useHistory, Link, useLocation } from 'react-router-dom';
import ProfilePic from "../assets/images/user_avatar.png"
import dropdownProfile from "../assets/images/profile_drop.svg"
import subscribtionDrop from "../assets/images/subscribe_drop.svg"
import Logo from "../assets/images/Logo.svg";
import pathNames from "../router/RoutePath";
import { Dropdown } from 'react-bootstrap';
import { LogoutAction } from '../redux/actions/AuthActions';
import { useDispatch, useSelector } from 'react-redux';
import { FetchUserSubscriptionDataAction } from '../redux/actions/PaymentActions';
import { FetchSearchCountAction } from "../redux/actions/AuthActions";

export default function Header() {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const logOut = () => {
        dispatch(LogoutAction(history));
    }
    const userDetails = JSON.parse(localStorage.getItem('user-subscription-details'));

    const FetchUserSubscriptionData = () => {
        dispatch(FetchUserSubscriptionDataAction());
    };

    const FetchSearchCountData = () => {
        dispatch(FetchSearchCountAction());
    };

    useEffect(() => {
        FetchSearchCountData();
        FetchUserSubscriptionData();
        if (userDetails && (!userDetails.client_subscription || userDetails.client_subscription === '' || userDetails.client_subscription === null)) {
            history.push('/subscription-packages')
        }
    }, []);

    let counterUserSubscription = useSelector((state) => state.PaymentResult.UserSubscriptionResponse);
    let counterSearchCounter = useSelector((state) => state.userAuth.SearchCount);
    useEffect(() => {
        if (counterUserSubscription.status) {
            if (counterUserSubscription.data !== null && counterSearchCounter.data !== null) {
                if (counterUserSubscription.data.client_product_name === 'Intro' && counterSearchCounter.data >= 1) {
                    if (location.pathname !== '/home'
                        && location.pathname !== '/profile-info'
                        && location.pathname !== '/subscription-packages'
                        && location.pathname !== '/checkout'
                        && location.pathname !== '/subscribed'
                        && location.pathname !== '/subscriptions-limit'
                        && location.pathname !== '/submittal-details'
                    ) {
                        console.log(location.pathname);
                        history.push('/subscriptions-limit');
                    }
                }
            }
        }
    }, [counterUserSubscription]);
    return (
        <div className="mainNav">
            <div className="logoDiv">
                <Link to={pathNames.mainSearch}><img className="navLogo" src={Logo} alt="Logo Unavailable" /></Link>
            </div>
            <div className="aboutAccount">
                <img className="profilePic" src={ProfilePic} alt="Profile Unavailable" />
                <div>
                    <Dropdown className="">
                        <Dropdown.Toggle className="drop-down-head" id="dropdown-basic">
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="drop-down-menu">
                            {/* <Dropdown.Item ><Link className="dropDownInfo" to={pathNames.ProfileInfo}><span className="dropOption">10</span><span className="dropRepresent">Reports Credit</span></Link></Dropdown.Item> */}
                            <Link className="dropDownInfo" to={pathNames.ProfileInfo}><span className="dropOption"><img className="titleLogo" src={dropdownProfile} alt="dropdown" /></span><span className="dropRepresent">My Profile</span></Link>
                            <Dropdown.Divider />
                            <Link className="dropDownInfo" to={pathNames.subscriptionPackages}><span className="dropOption"><img className="titleLogo" src={subscribtionDrop} alt="dropdown" /></span><span className="dropRepresent">Subscription</span></Link>
                            <Dropdown.Divider />
                            <button className="logoutBtn" onClick={logOut}>Logout</button>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </div>
    )
}

