import { BrowserRouter as Router, Switch } from "react-router-dom";
import authRoutes from "./auth";
import protectedRoutes from "./protected";
import ProtectedRoute from "./protected/ProtectedRoute";
import UnprotectedRoute from "./UnprotectedRoute";


export default function AppRoutes() {
    return (
        <Router>
            <Switch>
                {authRoutes.map((item, index) => {
                    return <UnprotectedRoute key={index} exact path={item.path} component={item.component} />
                })}

                {protectedRoutes.map((item, index) => {
                    return <ProtectedRoute key={index} exact path={item.path} component={item.component} />
                })}
            </Switch>
        </Router>
    )
}