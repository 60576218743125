import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import pathNames from "../router/RoutePath";
import { useDispatch } from 'react-redux';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { FetchJurSearchedResultsAction } from '../redux/actions/KnackActions';

export default function MainSearch() {
    let [constraints, setConstraints] = useState({
        page: "1",
        limit: "50",
        order: "asc",
        filters: "",
        state: ""
    });
    const [searchedItem, setSearchedItem] = useState('');
    let queryLabel = null;
    let [jurisdictionsFilter, setJurisdictionsFilter] = useState('');
    let [jurisdictionsStateFilter, setJurisdictionsStateFilter] = useState('');
    let history = useHistory();
    const dispatch = useDispatch();
    const FetchJurSearchedResultsData = (obj, history) => {
        dispatch(FetchJurSearchedResultsAction(obj, history));
    };
    const SetJurisdictionsFilterState = () => {
        if (searchedItem !== "" && searchedItem !== undefined && searchedItem !== null) {
            queryLabel = searchedItem.label;
            let queryLabelArr = queryLabel.split(', ');
            var queryJur = "";
            var queryState = "";
            if (queryLabelArr.length === 2) {
                queryJur = queryLabel;
                queryState = "";
            }
            else if (queryLabelArr.length === 1) {
                queryJur = queryLabel;
                queryState = "";
            } else {
                queryJur = queryLabelArr[queryLabelArr.length - 3];
                queryState = queryLabelArr[queryLabelArr.length - 2];
            }
            if (queryJur && queryJur.length >= 2) {
                setJurisdictionsStateFilter(queryState);
                setJurisdictionsFilter(queryJur);
            }
        }
    }
    useEffect(() => {
        if (jurisdictionsFilter && jurisdictionsFilter.length >= 2) {
            let obj = { ...constraints };
            obj.page = 1;
            obj.limit = '50';
            obj.order = 'asc';
            obj.filters = jurisdictionsFilter;
            obj.state = jurisdictionsStateFilter;
            console.log(obj);
            setConstraints({ ...obj });
            FetchJurSearchedResultsData(obj, history);
        }
    }, [jurisdictionsFilter])

    return (
        <>
            <section className="searchBackground">
                <div className="searchBox">
                    <h5>Search</h5>
                    <p>Enter Address</p>
                    <div className="displayDiv">
                        <GooglePlacesAutocomplete
                            apiKey="AIzaSyCiSwHoCr13O6AVKbUSFQ5gjzy7DIlY7Ac"
                            minLength={2}
                            autoFocus={true}
                            selectProps={{
                                searchedItem,
                                onChange: setSearchedItem,
                            }}
                        />
                        <span className="searchBtnDiv">
                            <button className="searchBtn mt-3" onClick={SetJurisdictionsFilterState}>Search</button>
                        </span>
                    </div>
                    <div className="searchLink">
                        <span>
                            or Search by
                            <Link to={pathNames.states}>States,</Link>
                            <Link to={pathNames.CountiesPage}>Counties,</Link>
                            <Link to={pathNames.jurisdictions}>Jurisdictions,</Link>
                        </span>
                    </div>
                </div>
            </section>
        </>

    )
}