import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from 'react-router-dom';
import homeLogo from "../assets/images/home_icon.svg"
import rightArrow from "../assets/images/right_arrow.svg"
import backIcon from "../assets/images/back_errow.svg"
import exportBox from "../assets/images/export_box.svg"
import pathNames from "../router/RoutePath";
import {
    FetchSubmittalRequirementAction,
} from '../redux/actions/KnackActions';
import { useDispatch, useSelector } from 'react-redux';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { FetchUserSubscriptionDataAction } from '../redux/actions/PaymentActions';
import axios from 'axios';
import { baseUrl, setReportCreditPoint } from "../constant";

export default function SubmitDetail() {

    let location = useLocation();
    let history = useHistory();
    const requirementId = location.state[0].requirement_id;
    const jurisdictionId = location.state[1].jurisdiction_id;
    let [loading, setLoading] = useState(false);
    let [requirementData, setRequirementData] = useState('');
    let [exportData, setSetExportData] = useState(false);
    const dispatch = useDispatch();
    const FetchSubmittalRequirementData = (id) => {
        dispatch(FetchSubmittalRequirementAction(id));
    };

    const FetchUserSubscriptionData = () => {
        dispatch(FetchUserSubscriptionDataAction());
    };

    useEffect(() => {
        setLoading(true);
        FetchUserSubscriptionData();
        FetchSubmittalRequirementData(requirementId);
    }, []);

    let SubmittalRequirement = useSelector((state) => state.knackResult.SubmittalRequirementData);
    let counterUserSubscription = useSelector((state) => state.PaymentResult.UserSubscriptionResponse);
    // Fetch Knack APIs Data
    useEffect(() => {
        if (counterUserSubscription.status) {
            if (counterUserSubscription.data !== null) {
                setReportCredit();
                if (counterUserSubscription.data.client_product_name === 'Professional' || counterUserSubscription.data.client_product_name === 'Base') {
                    setSetExportData(true);
                }
                else {
                    setSetExportData(false);
                }
            }
        }
        else {
            setSetExportData(false);
        }
        console.log(SubmittalRequirement);
        if (SubmittalRequirement.status) {
            let data = SubmittalRequirement.data;
            setRequirementData(data);
            setLoading(false);
        } else {
            console.log(SubmittalRequirement.message);
        }
    }, [SubmittalRequirement, counterUserSubscription]);

    useEffect(() => {
        // console.log(requirementData);
    }, [requirementData]);

    const setReportCredit = async () => {
        const token = await localStorage.getItem('user-token');
        const url = baseUrl + '/' + setReportCreditPoint;
        return await axios.get(url, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            },
        }).then(function (response) {
            if (response.data.status) {
                console.log(response.data);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(15);
        // const title = "Submittal Requirement Data";
        doc.text('', marginLeft, 40);
        doc.autoTable({
            html: '#print-data',
            theme: 'grid',
            didDrawPage: function (data) {
                // Header
                doc.setFontSize(15)
                doc.setTextColor(40)
                doc.text('Permit Place', data.settings.margin.left, 22)

                // Footer
                doc.setFontSize(8)
                var pageSize = doc.internal.pageSize
                var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
                doc.text('Permit Place - Due Diligence', data.settings.margin.left, pageHeight - 10)
            },
        });
        doc.save("Due Diligence.pdf")
    }
    return (
        <section>
            <div className="searchPart">
                <div className="searchBox mainDetail">
                    <div>
                        <h5>Submit Request Details</h5>
                    </div>
                </div>
            </div>
            <div className="submitRequestDetail">
                <div className="dataOption">
                    <div className="homeDiv detailDiv">
                        <img src={homeLogo} alt="" />
                        <img src={rightArrow} alt="" />
                        <Link className="placeName" to={pathNames.jurisdictions}> <h6>Jurisdiction</h6></Link>
                        <img src={rightArrow} alt="" />
                        <h6>Jurisdiction Details</h6>
                        <img src={rightArrow} alt="" />
                        <h6>Submit Request Details</h6>
                    </div>
                    <button className='backButton' onClick={() => history.goBack()}><i className="fa fa-arrow-circle-left" />Go Back</button>
                </div>
                <div className="requestTitle">
                    <h5>Submittal Requirement Data</h5>
                    {exportData &&
                        <Link className="placeName" to={
                            {
                                pathname: pathNames.ExportDetails,
                                state: [{ requirement_id: requirementData.requirement_id }, { jurisdiction_id: jurisdictionId }]
                            }
                        }> <button className="exportBtn"> <img src={exportBox} alt="Export SVG" /> Export Details</button></Link>
                    }
                    {/* <button className="exportBtn" onClick={exportPDF}></button> */}
                </div>
                {loading ? <div className="loader"></div> : <div className="row" >
                    <div className="col-md-12">
                        <div className="requestDetail">
                            <h5>County</h5>
                            <h6>{requirementData.county_name}</h6>
                        </div>
                        <div className="requestDetail">
                            <h5>Permit Type</h5>
                            <h6>{requirementData.permit_type}</h6>
                        </div>
                        <div className="requestDetail">
                            <h5>Use Type</h5>
                            <h6>{requirementData.use_type}</h6>
                        </div>
                        <div className="requestDetail">
                            <h5>Building Submittal Method</h5>
                            <h6>{requirementData.building_submittal}</h6>
                        </div>
                        <div className="requestDetail">
                            <h5>Owner Signature / Landlord Authorization</h5>
                            <h6>{requirementData.owner_signature}</h6>
                        </div>
                        <div className="requestDetail">
                            <h5>Architectural Plans</h5>
                            <h6>{requirementData.architectural_plans}</h6>
                        </div>
                        <div className="requestDetail">
                            <h5>Plans Stamp Requirements</h5>
                            <h6>{requirementData.plans_stamp_requirements}</h6>
                        </div>
                        <div className="requestDetail">
                            <h5>Number of Plan Sets Required</h5>
                            <h6>{requirementData.number_of_plan}</h6>
                        </div>
                        <div className="requestDetail">
                            <h5>Expedited Review Available</h5>
                            <h6>{requirementData.expedited_review_available ? 'Yes' : 'No'}</h6>
                        </div>
                        <div className="requestDetail">
                            <h5>General Application Review Time Frame</h5>
                            <h6>{requirementData.general_application_review}</h6>
                        </div>
                        <div className="requestDetail">
                            <h5>Building Resubmittal Review Time</h5>
                            <h6>{requirementData.building_resubmittal_review}</h6>
                        </div>
                        <div className="requestDetail">
                            <h5>Separate MEP Application </h5>
                            <h6>{requirementData.separate_mep_application}</h6>
                        </div>
                        <div className="requestDetail">
                            <h5>MEP Submittal Method</h5>
                            <h6>{requirementData.mep_submittal_method}</h6>
                        </div>
                        <div className="requestDetail">
                            <h5>MEP Application</h5>
                            <h6>{requirementData.mep_application}</h6>
                        </div>
                        <div className="requestDetail">
                            <h5>MEP Plans</h5>
                            <h6>{requirementData.mep_plans}</h6>
                        </div>
                        <div className="requestDetail">
                            <h5>No. MEP of Plan Sets Required</h5>
                            <h6>{requirementData.mep_no_of_plan}</h6>
                        </div>
                        <div className="requestDetail">
                            <h5>MEP Review Times</h5>
                            <h6>{requirementData.mep_review_times}</h6>
                        </div>
                        <div className="requestDetail">
                            <h5>MEP Resubmittal Review Time</h5>
                            <h6>{requirementData.mep_resubmittal_review}</h6>
                        </div>
                        <div className="requestDetail">
                            <h5>Fee Schedule</h5>
                            <h6>{requirementData.fee_schedule}</h6>
                        </div>
                        <div className="requestDetail">
                            <h5>Fee Schedule Link</h5>
                            <h6>{requirementData.fee_schedule_link}</h6>
                        </div>
                        <div className="requestDetail">
                            <h5>Health Department Application Required</h5>
                            <h6>{requirementData.health_department_application}</h6>
                        </div>
                        <div className="requestDetail">
                            <h5>Health Department Review Time</h5>
                            <h6>{requirementData.health_department_review}</h6>
                        </div>
                        <div className="requestDetail">
                            <h5>Updated</h5>
                            <h6>{requirementData.updated}</h6>
                        </div>
                    </div>
                    <div className="row" style={{ display: "none" }}>
                        <div className="col-md-12">
                            <table responsive={"responsive"} id="print-data">
                                <tbody>
                                    <tr>
                                        <td colSpan={2}>Submittal Requirement Data</td>
                                    </tr>
                                    <tr>
                                        <td><b>County</b></td>
                                        <td>{requirementData.county_name}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Permit Type</b></td>
                                        <td>{requirementData.permit_type}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Use Type</b></td>
                                        <td>{requirementData.use_type}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Building Submittal Method</b></td>
                                        <td>{requirementData.building_submittal}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Owner Signature / Landlord Authorization</b></td>
                                        <td>{requirementData.owner_signature}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Architectural Plans</b></td>
                                        <td>{requirementData.architectural_plans}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Plans Stamp Requirements</b></td>
                                        <td>{requirementData.plans_stamp_requirements}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Number of Plan Sets Required</b></td>
                                        <td>{requirementData.number_of_plan}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Expedited Review Available</b></td>
                                        <td>{requirementData.expedited_review_available ? 'Yes' : 'No'}</td>
                                    </tr>
                                    <tr>
                                        <td><b>General Application Review Time Frame</b></td>
                                        <td>{requirementData.general_application_review}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Building Resubmittal Review Time</b></td>
                                        <td>{requirementData.building_resubmittal_review}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Separate MEP Application</b></td>
                                        <td>{requirementData.separate_mep_application}</td>
                                    </tr>
                                    <tr>
                                        <td><b>MEP Submittal Method</b></td>
                                        <td>{requirementData.mep_submittal_method}</td>
                                    </tr>
                                    <tr>
                                        <td><b>MEP Application</b></td>
                                        <td>{requirementData.mep_application}</td>
                                    </tr>
                                    <tr>
                                        <td><b>MEP Plans</b></td>
                                        <td>{requirementData.mep_plans}</td>
                                    </tr>
                                    <tr>
                                        <td><b>No. MEP of Plan Sets Required</b></td>
                                        <td>{requirementData.mep_no_of_plan}</td>
                                    </tr>
                                    <tr>
                                        <td><b>MEP Review Times</b></td>
                                        <td>{requirementData.mep_review_times}</td>
                                    </tr>
                                    <tr>
                                        <td><b>MEP Resubmittal Review Time</b></td>
                                        <td>{requirementData.mep_resubmittal_review}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Fee Schedule</b></td>
                                        <td>{requirementData.fee_schedule}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Health Department Application Required</b></td>
                                        <td>{requirementData.health_department_application}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Health Department Review Time</b></td>
                                        <td>{requirementData.health_department_review}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                }
                <Link className="goBack" to={pathNames.jurisdictions}>
                    <span><img className="eyeIcon" src={backIcon} alt="eyeIcon" /></span>
                    <span>Back to Jurisdiction</span>
                </Link>
            </div>
        </section >

    )
}