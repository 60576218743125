import React from 'react'
import { saveAs } from "file-saver";


const DownloadPdf = (props) => {
    const saveFile = () => {
        saveAs(
            props.PdfLink,
            "Due-Diligence.pdf"
        );
    };
    return (
        <button className="btn-submit" type="button" onClick={saveFile}>
            <span id="button-text">
                <i class="fa fa-download"></i> Download PDF File
            </span>
        </button>
    )
}
export default DownloadPdf


