import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from 'react-router-dom';
import homeLogo from "../assets/images/home_icon.svg"
import rightArrow from "../assets/images/right_arrow.svg"
import backIcon from "../assets/images/back_errow.svg"
import pathNames from "../router/RoutePath";
import {
    FetchContactDataAction,
} from '../redux/actions/KnackActions';
import { useDispatch, useSelector } from 'react-redux';

export default function SubmitDetail() {

    let location = useLocation();
    let history = useHistory();
    let contactId = location.state;
    let [loading, setLoading] = useState(false);
    let [contactData, setContactData] = useState('');
    const dispatch = useDispatch();
    const FetchContactData = (id) => {
        dispatch(FetchContactDataAction(id));
    };

    useEffect(() => {
        setLoading(true);
        FetchContactData(contactId);
    }, []);

    let ContactInformation = useSelector((state) => state.knackResult.ContactData);

    // Fetch Knack APIs Data
    useEffect(() => {
        if (ContactInformation !== '') {
            let status = ContactInformation.status;
            if (status === true) {
                let data = ContactInformation.data;
                setContactData(data);
                setLoading(false);
            }
            else if (status === false) {
                console.log(ContactInformation.message);
            }
        }
    }, [ContactInformation]);

    useEffect(() => {
        // console.log(contactData);
    }, [contactData]);
    return (
        <section>
            <div className="searchPart">
                <div className="searchBox mainDetail">
                    <div>
                        <h5>Submit Request Details</h5>
                    </div>
                </div>
            </div>
            <div className="submitRequestDetail">
                <div className="dataOption">
                    <div className="homeDiv detailDiv">
                        <img src={homeLogo} alt="" />
                        <img src={rightArrow} alt="" />
                        <Link className="placeName" to={pathNames.jurisdictions}> <h6>Jurisdiction</h6></Link>
                        <img src={rightArrow} alt="" />
                        <h6>Jurisdiction Details</h6>
                        <img src={rightArrow} alt="" />
                        <h6>Contact Details</h6>
                    </div>
                    <button className='backButton' onClick={() => history.goBack()}><i className="fa fa-arrow-circle-left" />Go Back</button>
                </div>
                <div className="requestTitle">
                    <h5>Contact Details Data</h5>
                </div>
                {loading ? <div className="loader"></div> : <div className="row">
                    <div className="col-md-12">
                        <div className="requestDetail">
                            <h5>State</h5>
                            <h6><Link to={
                                {
                                    pathname: pathNames.StateDetail,
                                    state: contactData.state_id
                                }
                            } className="placeName">{contactData.state_name}</Link></h6>
                        </div>
                        <div className="requestDetail">
                            <h5>County</h5>
                            <h6><Link to={
                                {
                                    pathname: pathNames.CountyDetails,
                                    state: contactData.county_id
                                }
                            } className="placeName">{contactData.county_name}</Link></h6>
                        </div>
                        <div className="requestDetail">
                            <h5>Jurisdiction</h5>
                            <h6><Link to={
                                {
                                    pathname: pathNames.JurisdictionDetails,
                                    state: [{ jurisdiction_id: contactData.jurisdiction_id }, { county_id: contactData.county_id }]
                                }
                            } className="placeName">{contactData.jurisdiction_name}</Link></h6>
                        </div>
                        <div className="requestDetail">
                            <h5>Agency</h5>
                            <h6>{contactData.agency_name}</h6>
                        </div>
                        <div className="requestDetail">
                            <h5>Agency Type</h5>
                            <h6>{contactData.agency_type}</h6>
                        </div>
                        <div className="requestDetail">
                            <h5>Name</h5>
                            <h6>{contactData.contact_name}</h6>
                        </div>
                        <div className="requestDetail">
                            <h5>Title</h5>
                            <h6>{contactData.contact_title}</h6>
                        </div>
                        <div className="requestDetail">
                            <h5>Division</h5>
                            <h6>{contactData.contact_division}</h6>
                        </div>
                        <div className="requestDetail">
                            <h5>Phone</h5>
                            <h6><a className="placeName" href={"tel:" + contactData.contact_phone}>{contactData.contact_phone}</a></h6>
                        </div>
                        <div className="requestDetail">
                            <h5>Ext.</h5>
                            <h6>{contactData.contact_ext}</h6>
                        </div>
                        <div className="requestDetail">
                            <h5>Direct Phone</h5>
                            <h6><a className="placeName" href={"tel:" + contactData.contact_direct_phone}>{contactData.contact_direct_phone}</a></h6>
                        </div>
                        <div className="requestDetail">
                            <h5>Cell Phone</h5>
                            <h6><a className="placeName" href={"tel:" + contactData.contact_cell_phone}>{contactData.contact_cell_phone}</a></h6>
                        </div>
                        <div className="requestDetail">
                            <h5>Fax Number</h5>
                            <h6>{contactData.contact_fax}</h6>
                        </div>
                        <div className="requestDetail">
                            <h5>Email</h5>
                            <h6><a className="placeName" href={"mailto:" + contactData.contact_email}>{contactData.contact_email}</a></h6>
                        </div>
                        <div className="requestDetail">
                            <h5>Web Address(URL)</h5>
                            <h6><a className="placeName" target={"_blank"} rel="noopener noreferrer" href={contactData.web_address} >{contactData.web_address}</a></h6>
                        </div>
                    </div>
                </div>
                }
                <Link className="goBack" to={pathNames.jurisdictions}>
                    <span><img className="eyeIcon" src={backIcon} alt="eyeIcon" /></span>
                    <span>Back to Jurisdiction</span>
                </Link>


            </div>
        </section>

    )
}