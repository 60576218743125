import React, { useState, useEffect } from "react";
import { Alert } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import Logo from "../../assets/images/Logo.svg";
import pathNames from "../../router/RoutePath";
import { useDispatch, useSelector } from 'react-redux';
import { ResetPasswordAction } from '../../redux/actions/AuthActions';
import eyeIcon from "../../assets/images/eye_icon.svg"

export default function ResetPassword() {

    const history = useHistory();
    const dispatch = useDispatch();
    let [resetSuccessMessage, setResetSuccessMessage] = useState("")
    let [resetSuccessAlert, setResetSuccessAlert] = useState(false);
    let [resetErrorMessage, setResetErrorMessage] = useState("");
    let [resetErrorAlert, setResetErrorAlert] = useState(false);
    let [resetToken, setResetToken] = useState("");
    let strongPasswordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/;
    let emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    let [error, setError] = useState({ email: "", password: "", password_confirmation: "" });
    let [fields, setField] = useState({
        email: "",
        password: "",
        password_confirmation: "",
        token: "",
    });
    const [resetPasswordShown, setResetPasswordShown] = useState(false);

    const togglePassword = () => {
        setResetPasswordShown(!resetPasswordShown);
    };

    const checkValidity = (inputName, inputValue) => {
        const errorStringEmail = "Your email must be more than 8 characters long. Should be valid Email.";
        const errorStringPassword = "Your Password must be more than 8 characters long. Should contains at least 1 Uppercase, 1 Lowercase, 1 Number and 1 Special Character.";
        switch (inputName) {
            case "email":
                let emailValidator = emailRegex.test(inputValue);
                inputValue.length < 8 ? setError({ ...error, [inputName]: errorStringEmail }) : setError({ ...error, [inputName]: "" });
                if (!emailValidator) setError({ ...error, [inputName]: errorStringEmail });
                break;
            case "password":
                let passwordValidator = strongPasswordRegex.test(inputValue);
                inputValue.length < 8 ? setError({ ...error, [inputName]: errorStringPassword }) : setError({ ...error, [inputName]: "" });
                if (!passwordValidator) setError({ ...error, [inputName]: errorStringPassword });
                break;
            case "password_confirmation":
                let cpasswordValidator = strongPasswordRegex.test(inputValue);
                inputValue.length < 8 ? setError({ ...error, [inputName]: errorStringPassword }) : setError({ ...error, [inputName]: "" });
                if (!cpasswordValidator) setError({ ...error, [inputName]: errorStringPassword });
                break;
            default:
                break;
        }
    };

    const handleFieldChange = e => {
        setResetErrorMessage("");
        let name = e.target.id;
        if (e.target.value !== "") {
            checkValidity(e.target.id, e.target.value);
            let inputObject = {
                ...fields,
                [e.target.id]: e.target.value, token: resetToken
            }
            setField(inputObject);
        }
        else {
            setError({ ...error, [name]: "" });
        }
    }

    let counter = useSelector((state) => state);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const reset_token = queryParams.get('token');
        if (reset_token === null && reset_token === '') {
            history.push('login/')
        }
        setResetErrorMessage("");
        setResetSuccessMessage("");
        setResetToken(reset_token);
        let status = counter.userAuth.authResponse.status;
        let message = counter.userAuth.authResponse.message;
        if (status === true) {
            setResetSuccessMessage(counter.userAuth.authResponse.data);
            document.getElementById("email").value = "";
            document.getElementById("password").value = "";
            document.getElementById("password_confirmation").value = "";
        }
        else if (status === false && message === "Validation Errors") {
            setResetErrorMessage(counter.userAuth.authResponse.data);
        }
        else if (status === false && (message === "Password not reset")) {
            setResetErrorMessage(counter.userAuth.authResponse.data);
        }
        else if (status === false && (message === "This user does not exist")) {
            setResetErrorMessage(counter.userAuth.authResponse.message);
        }
        else if (status === false) {
            setResetErrorMessage(counter.userAuth.authResponse.message);
        }
    }, [counter])

    const UserResetPassword = (e) => {
        e.preventDefault();
        let errorStringConfirmPassword = "Password and Confirm Password does not match.";
        if (!checkPasswordMatch(fields.password, fields.password_confirmation)) {
            setError({ ...error, password_confirmation: errorStringConfirmPassword });
        } else {
            let errorStatus = isErrorsObjectEmpty(error);
            if (errorStatus) {
                setResetErrorMessage('');
                dispatch(ResetPasswordAction(fields, history));
            }
            else {
                setResetErrorMessage('Please Fulfill all requirements.');
            }
        }

    };

    function isErrorsObjectEmpty(obj) {
        if (obj.email.length === 0 && obj.password.length === 0 && obj.password_confirmation.length === 0) {
            return true;
        }
        return false;
    }

    useEffect(() => {
        if (resetErrorMessage !== "") {
            setResetErrorAlert(true);
            setResetSuccessAlert(false);
        }
    }, [resetErrorMessage])

    useEffect(() => {
        if (resetSuccessMessage !== "") {
            setResetErrorAlert(false);
            setResetSuccessAlert(true);
        }
    }, [resetSuccessMessage])

    const checkPasswordMatch = (password, password_confirmation) => {
        return password === password_confirmation ? true : false;
    }

    return (
        <section className="loginSection">
            <div className="container-fluid g-0">
                <div className="row g-0">
                    <div className="col-md-6" >
                        <div className="backgroundImage"></div>
                    </div>
                    <div className="col-md-6" >
                        <div className="mainLogin">
                            <div className="logo">
                                <img src={Logo} alt="" />
                            </div>
                            <div className="mainContent">
                                <h2 className="mb-0">
                                    Reset Password
                                </h2>
                                <p className="mb-5">Enter the email address associated with your account.</p>
                                {resetErrorMessage && resetErrorAlert !== false && (<Alert variant="danger" onClose={() => setResetErrorAlert(false)} dismissible>{resetErrorMessage}</Alert>)}
                                {resetSuccessMessage && resetSuccessAlert !== false && (<Alert variant="success" onClose={() => setResetSuccessAlert(false)} dismissible>{resetSuccessMessage}</Alert>)}
                                <form onSubmit={UserResetPassword}>
                                    <div className="formInput">
                                        <label>Email</label>
                                        <input type="email" placeholder="johndoe@gmail.com" id="email" onChange={handleFieldChange} maxLength="50" required="required" />
                                        {error.email.length > 0 && <span className='error'>{error.email}</span>}
                                    </div>
                                    <div className="formInput">
                                        <label>Password</label>
                                        <div className="input-group">
                                            <input style={{ width: "90%" }} type={resetPasswordShown ? "text" : "password"} placeholder="*********" id="password" onChange={handleFieldChange} maxLength="25" required="required" />
                                            <button className="view-password-btn" onClick={togglePassword} type="button"><img className="eye-icon-password" src={eyeIcon} alt="eyeIcon" /></button>
                                        </div>
                                        {error.password.length > 0 && <span className='error'>{error.password}</span>}
                                    </div>
                                    <div className="formInput">
                                        <label>Confirm Password</label>
                                        <div className="input-group">
                                            <input style={{ width: "90%" }} type={resetPasswordShown ? "text" : "password"} placeholder="*********" id="password_confirmation" onChange={handleFieldChange} maxLength="25" required="required" />
                                            <button className="view-password-btn" onClick={togglePassword} type="button"><img className="eye-icon-password" src={eyeIcon} alt="eyeIcon" /></button>
                                        </div>
                                        {error.password_confirmation.length > 0 && <span className='error'>{error.password_confirmation}</span>}
                                    </div>
                                    <button type="submit" className="btnLogin">Reset Password</button>
                                </form>
                                <div className="signUpLink">
                                    <span><Link to={pathNames.login}>Back to Login</Link></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}