import HttpService from './HttpService';
import {
    fetchAllStatesData,
    fetchAllCountiesData,
    fetchAllJurisdictionsData,
    fetchJurisdictionData,
    fetchJurisdictionContactsData,
    fetchJurisdictionSearchedData,
    fetchCountySubmittalRequirementsData,
    fetchSubmittalRequirementData,
    fetchContactData,
    fetchStateData,
    fetchStateCountiesData,
    fetchCountyData,
    fetchCountyJurisdictionsData,
    exportPdfDocData
} from "../constant";


// States Service
export const FetchAllStatesService = (constraints) => {
    const http = new HttpService();
    const tokenId = "user-token";
    let params = '?page=' + constraints.page + '&limit=' + constraints.limit + '&order=' + constraints.order + '&filters=' + constraints.filters;
    return http.getData(fetchAllStatesData + params, tokenId).then((data) => {
        return data;
    }).catch((error) => {
        return error;
    })
}

export const FetchStateService = (credentials) => {
    const http = new HttpService();
    const tokenId = "user-token";
    return http.getData(fetchStateData + '/' + credentials, tokenId).then((data) => {
        return data;
    }).catch((error) => {
        return error;
    })
}

export const FetchStateCountiesService = (constraints) => {
    const http = new HttpService();
    const tokenId = "user-token";
    let params = '?page=' + constraints.page + '&limit=' + constraints.limit + '&order=' + constraints.order + '&filters=' + constraints.filters + '&state=' + constraints.state;
    return http.getData(fetchStateCountiesData + params, tokenId).then((data) => {
        return data;
    }).catch((error) => {
        return error;
    })
}


// Counties Services

export const FetchCountyService = (credentials) => {
    const http = new HttpService();
    const tokenId = "user-token";
    return http.getData(fetchCountyData + '/' + credentials, tokenId).then((data) => {
        return data;
    }).catch((error) => {
        return error;
    })
}

export const FetchCountyJurisdictionsService = (constraints) => {
    const http = new HttpService();
    const tokenId = "user-token";
    let params = '?page=' + constraints.page + '&limit=' + constraints.limit + '&order=' + constraints.order + '&filters=' + constraints.filters + '&county=' + constraints.county;
    return http.getData(fetchCountyJurisdictionsData + params, tokenId).then((data) => {
        return data;
    }).catch((error) => {
        return error;
    })
}

export const FetchAllCountiesService = (constraints) => {
    const http = new HttpService();
    const tokenId = "user-token";
    let params = '?page=' + constraints.page + '&limit=' + constraints.limit + '&order=' + constraints.order + '&filters=' + constraints.filters;
    return http.getData(fetchAllCountiesData + params, tokenId).then((data) => {
        return data;
    }).catch((error) => {
        return error;
    })
}

export const FetchAllJurisdictionsService = (constraints) => {
    const http = new HttpService();
    const tokenId = "user-token";
    let params = '?page=' + constraints.page + '&limit=' + constraints.limit + '&order=' + constraints.order + '&filters=' + constraints.filters;
    return http.getData(fetchAllJurisdictionsData + params, tokenId).then((data) => {
        return data;
    }).catch((error) => {
        return error;
    })
}

export const FetchJurisdictionService = (credentials) => {
    const http = new HttpService();
    const tokenId = "user-token";
    return http.getData(fetchJurisdictionData + '/' + credentials, tokenId).then((data) => {
        return data;
    }).catch((error) => {
        return error;
    })
}

export const FetchJurisdictionContactsService = (credentials) => {
    const http = new HttpService();
    const tokenId = "user-token";
    return http.getData(fetchJurisdictionContactsData + '/' + credentials, tokenId).then((data) => {
        return data;
    }).catch((error) => {
        return error;
    })
}

export const FetchJurSearchedResultsService = (constraints) => {
    const http = new HttpService();
    const tokenId = "user-token";
    let params = '?page=' + constraints.page + '&limit=' + constraints.limit + '&order=' + constraints.order + '&filters=' + constraints.filters + '&state=' + constraints.state;
    return http.getData(fetchJurisdictionSearchedData + params, tokenId).then((data) => {
        return data;
    }).catch((error) => {
        return error;
    })
}

export const FetchCountySubmittalRequirementsService = (credentials) => {
    const http = new HttpService();
    const tokenId = "user-token";
    return http.getData(fetchCountySubmittalRequirementsData + '/' + credentials, tokenId).then((data) => {
        return data;
    }).catch((error) => {
        return error;
    })
}

export const FetchSubmittalRequirementService = (credentials) => {
    const http = new HttpService();
    const tokenId = "user-token";
    return http.getData(fetchSubmittalRequirementData + '/' + credentials, tokenId).then((data) => {
        return data;
    }).catch((error) => {
        return error;
    })
}

export const FetchContactDataService = (credentials) => {
    const http = new HttpService();
    const tokenId = "user-token";
    return http.getData(fetchContactData + '/' + credentials, tokenId).then((data) => {
        return data;
    }).catch((error) => {
        return error;
    })
}

export const ExportPdfDataService = (credentials) => {
    const http = new HttpService();
    const tokenId = "user-token";
    return http.postData(credentials, exportPdfDocData, tokenId).then((data) => {
        return data;
    }).catch((error) => {
        return error;;
    })
}