import * as ActionTypes from '../ActionTypes';
import {
    FetchAllStatesService,
    FetchAllCountiesService,
    FetchAllJurisdictionsService,
    FetchJurisdictionService,
    FetchJurisdictionContactsService,
    FetchJurSearchedResultsService,
    FetchCountySubmittalRequirementsService,
    FetchSubmittalRequirementService,
    FetchContactDataService,
    FetchStateService,
    FetchStateCountiesService,
    FetchCountyService,
    FetchCountyJurisdictionsService,
    ExportPdfDataService
} from '../../services/KnackService';

// States Actions 
export const FetchStatesAction = (constraints) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
        dispatch({ type: ActionTypes.LOADING });
        FetchAllStatesService(constraints).then((res) => {
            if (res.hasOwnProperty('status') && res.status === true) {
                dispatch({ type: ActionTypes.STATES_FETCH_SUCCESS, res });
            } else if (res.hasOwnProperty('status') && res.status === false) {
                dispatch({ type: ActionTypes.STATES_FETCH_ERROR, res });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
        })
    }
}

export const FetchStateAction = (constraints) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
        dispatch({ type: ActionTypes.LOADING });
        FetchStateService(constraints).then((res) => {
            if (res.hasOwnProperty('status') && res.status === true) {
                dispatch({ type: ActionTypes.STATE_FETCH_SUCCESS, res });
            } else if (res.hasOwnProperty('status') && res.status === false) {
                dispatch({ type: ActionTypes.STATE_FETCH_ERROR, res });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
        })
    }
}

export const FetchStateCountiesAction = (constraints) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
        dispatch({ type: ActionTypes.LOADING });
        FetchStateCountiesService(constraints).then((res) => {
            if (res.hasOwnProperty('status') && res.status === true) {
                dispatch({ type: ActionTypes.STATE_COUNTIES_FETCH_SUCCESS, res });
            } else if (res.hasOwnProperty('status') && res.status === false) {
                dispatch({ type: ActionTypes.STATE_COUNTIES_FETCH_ERROR, res });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
        })
    }
}

// Counties Actions

export const FetchCountyAction = (constraints) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
        dispatch({ type: ActionTypes.LOADING });
        FetchCountyService(constraints).then((res) => {
            if (res.hasOwnProperty('status') && res.status === true) {
                dispatch({ type: ActionTypes.COUNTY_FETCH_SUCCESS, res });
            } else if (res.hasOwnProperty('status') && res.status === false) {
                dispatch({ type: ActionTypes.COUNTY_FETCH_ERROR, res });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
        })
    }
}

export const FetchCountyJurisdictionsAction = (constraints) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
        dispatch({ type: ActionTypes.LOADING });
        FetchCountyJurisdictionsService(constraints).then((res) => {
            if (res.hasOwnProperty('status') && res.status === true) {
                dispatch({ type: ActionTypes.COUNTY_JURISDICTIONS_FETCH_SUCCESS, res });
            } else if (res.hasOwnProperty('status') && res.status === false) {
                dispatch({ type: ActionTypes.COUNTY_JURISDICTIONS_FETCH_ERROR, res });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
        })
    }
}

export const FetchCountiesAction = (constraints) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
        dispatch({ type: ActionTypes.LOADING });
        FetchAllCountiesService(constraints).then((res) => {
            if (res.hasOwnProperty('status') && res.status === true) {
                dispatch({ type: ActionTypes.COUNTIES_FETCH_SUCCESS, res });
            } else if (res.hasOwnProperty('status') && res.status === false) {
                dispatch({ type: ActionTypes.COUNTIES_FETCH_ERROR, res });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
        })
    }
}

// Jurisdictions Actions

export const FetchJurisdictionsAction = (constraints) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
        dispatch({ type: ActionTypes.LOADING });
        FetchAllJurisdictionsService(constraints).then((res) => {
            if (res.hasOwnProperty('status') && res.status === true) {
                dispatch({ type: ActionTypes.JURISDICTIONS_FETCH_SUCCESS, res });
            } else if (res.hasOwnProperty('status') && res.status === false) {
                dispatch({ type: ActionTypes.JURISDICTIONS_FETCH_ERROR, res });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
        })
    }
}

export const FetchJurisdictionAction = (constraints) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
        dispatch({ type: ActionTypes.LOADING });
        FetchJurisdictionService(constraints).then((res) => {
            if (res.hasOwnProperty('status') && res.status === true) {
                dispatch({ type: ActionTypes.JURISDICTION_FETCH_SUCCESS, res });
            } else if (res.hasOwnProperty('status') && res.status === false) {
                dispatch({ type: ActionTypes.JURISDICTION_FETCH_ERROR, res });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
        })
    }
}

export const FetchJurisdictionContactsAction = (constraints) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
        dispatch({ type: ActionTypes.LOADING });
        FetchJurisdictionContactsService(constraints).then((res) => {
            if (res.hasOwnProperty('status') && res.status === true) {
                dispatch({ type: ActionTypes.JURISDICTION_CONTACTS_FETCH_SUCCESS, res });
            } else if (res.hasOwnProperty('status') && res.status === false) {
                dispatch({ type: ActionTypes.JURISDICTION_CONTACTS_FETCH_ERROR, res });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
        })
    }
}

export const FetchJurSearchedResultsAction = (constraints, history) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
        dispatch({ type: ActionTypes.LOADING });
        FetchJurSearchedResultsService(constraints).then((res) => {
            if (res.hasOwnProperty('status') && res.status === true) {
                // console.log(res);
                history.push({
                    pathname: '/search-result',
                    query: constraints.filters,
                    state: true
                });
                dispatch({ type: ActionTypes.SEARCH_RESULTS_FETCH_SUCCESS, res });
            } else if (res.hasOwnProperty('status') && res.status === false) {
                dispatch({ type: ActionTypes.SEARCH_RESULTS_FETCH_ERROR, res });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
        })
    }
}

export const FetchCountySubmittalRequirementsAction = (constraints) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
        dispatch({ type: ActionTypes.LOADING });
        FetchCountySubmittalRequirementsService(constraints).then((res) => {
            if (res.hasOwnProperty('status') && res.status === true) {
                dispatch({ type: ActionTypes.JURISDICTION_SUBMITTAL_REQUIREMENTS_FETCH_SUCCESS, res });
            } else if (res.hasOwnProperty('status') && res.status === false) {
                dispatch({ type: ActionTypes.JURISDICTION_SUBMITTAL_REQUIREMENTS_FETCH_ERROR, res });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
        })
    }
}

export const FetchSubmittalRequirementAction = (constraints) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
        dispatch({ type: ActionTypes.LOADING });
        FetchSubmittalRequirementService(constraints).then((res) => {
            if (res.hasOwnProperty('status') && res.status === true) {
                dispatch({ type: ActionTypes.SUBMITTAL_REQUIREMENT_FETCH_SUCCESS, res });
            } else if (res.hasOwnProperty('status') && res.status === false) {
                dispatch({ type: ActionTypes.SUBMITTAL_REQUIREMENT_FETCH_ERROR, res });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
        })
    }
}

export const FetchContactDataAction = (constraints) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
        dispatch({ type: ActionTypes.LOADING });
        FetchContactDataService(constraints).then((res) => {
            if (res.hasOwnProperty('status') && res.status === true) {
                dispatch({ type: ActionTypes.CONTACT_FETCH_SUCCESS, res });
            } else if (res.hasOwnProperty('status') && res.status === false) {
                dispatch({ type: ActionTypes.CONTACT_FETCH_ERROR, res });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
        })
    }
}


export const ExportPdfDataAction = (credentials) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
        dispatch({ type: ActionTypes.LOADING });
        ExportPdfDataService(credentials).then((res) => {
            if (res.hasOwnProperty('status') && res.status === true) {
                dispatch({ type: ActionTypes.EXPORT_PDF_SUCCESS, res });
            } else if (res.hasOwnProperty('status') && res.status === false) {
                dispatch({ type: ActionTypes.EXPORT_PDF_ERROR, res });
            }
        }, error => {
            dispatch({ type: ActionTypes.CODE_ERROR, error })
        })
    }
}
