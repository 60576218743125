import pathNames from "../RoutePath";
import Login from "../../views/auth/Login";
import SignUp from "../../views/auth/SignUp";
import ForgotPassword from "../../views/auth/ForgotPassword";
import ResetPassword from "../../views/auth/ResetPassword";
import Registered from "../../views/auth/Registered";
import ResendLink from "../../views/auth/ResendLink";

const authRoutes = [
    { path: "/", component: Login },
    { path: pathNames.login, component: Login },
    { path: pathNames.signUp, component: SignUp },
    { path: pathNames.forgotPassword, component: ForgotPassword },
    { path: pathNames.resentVerificationLink, component: ResendLink },
    { path: pathNames.Registered, component: Registered },
    { path: pathNames.ResetPassword, component: ResetPassword },
]

export default authRoutes;