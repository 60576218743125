import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import card from "../assets/images/credit-card.svg"
import checkout from "../assets/images/money-check.svg"
import 'font-awesome/css/font-awesome.min.css';
import CheckoutForm from '../components/CheckoutForm';
import { useDispatch, useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
    FetchStripePlanAction,
    CreatePaymentIntentAction
} from '../redux/actions/PaymentActions';
import getSymbolFromCurrency from 'currency-symbol-map'
import { stripePublicKey } from "../constant";
import { Alert } from 'react-bootstrap';
import pathNames from "../router/RoutePath";
const stripePromise = loadStripe(stripePublicKey);

const Checkout = () => {

    let location = useLocation();
    let price_id = location.state;

    let [clientSecret, setClientSecret] = useState("");
    let [subscriptionId, setSubscriptionId] = useState("");
    let [planRecord, setPlanRecord] = useState('');
    let [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const FetchStripePlanData = (price) => {
        dispatch(FetchStripePlanAction(price));
    };

    const CreatePaymentIntentData = (price) => {
        dispatch(CreatePaymentIntentAction(price));
    };

    useEffect(() => {
        setLoading(true);
        CreatePaymentIntentData(price_id);
        FetchStripePlanData(price_id);
    }, []);

    let counterPaymentIntent = useSelector((state) => state.PaymentResult.PaymentIntentResponse);
    let counterPaymentPlan = useSelector((state) => state.PaymentResult.StripePlanResponse);

    useEffect(() => {
        if (counterPaymentIntent && counterPaymentIntent.status) {
            if (counterPaymentIntent && counterPaymentIntent.data !== null) {
                setClientSecret(counterPaymentIntent.data.client_secret ?? null);
                setSubscriptionId(counterPaymentIntent.data.subscription_id ?? null);
                setLoading(false);
            }
            else {
                setClientSecret('');
            }
        }
        if (counterPaymentPlan && counterPaymentPlan.status) {
            setPlanRecord(counterPaymentPlan.data);
        }
    }, [counterPaymentPlan, counterPaymentIntent])

    useEffect(() => {
        // console.log('Client Secret');
    }, [clientSecret])

    return (
        <>
            <section className="checkout-page">
                {loading ? <div className="loader"></div> :
                    <div className='payment-method'>
                        {clientSecret && (
                            <div className="row">
                                <div className="col-md-6 col-xs-12">
                                    <img style={{ height: "1.75rem" }} src={checkout} alt="Subscription Buy Icon" />
                                    <h3 style={{ color: "#24a859" }}>{planRecord.product_name}</h3>
                                    <h4>{planRecord.plan_amount === 0 ? 'Free' : getSymbolFromCurrency(planRecord.plan_currency) + planRecord.plan_amount}<span style={{ textTransform: 'capitalize' }}>{planRecord.plan_amount === 0 ? '' : ' / ' + planRecord.interval}</span></h4>
                                    <p className="subscription-package-description">{planRecord.product_description}</p>
                                </div>
                                <div className="col-md-6 col-xs-12">
                                    <img style={{ height: "1.75rem" }} src={card} alt="Card Details Icon" />
                                    <h3>Pay with card</h3>
                                    {clientSecret && subscriptionId &&
                                        <Elements stripe={stripePromise} options={{
                                            clientSecret,
                                            theme: 'stripe'
                                        }}>
                                            <CheckoutForm Subscription={subscriptionId} />
                                        </Elements>
                                    }
                                </div>
                            </div>
                        )}
                        {!clientSecret && (
                            <div className="row">
                                <div className="col-md-12 col-xs-12">
                                    <Alert variant="danger">Something went wrong. Please Try Again. <Link to={pathNames.subscriptionPackages}> Click Here</Link></Alert>
                                </div>
                            </div>
                        )}
                    </div>
                }
            </section>
        </>
    )
}

export default Checkout;
