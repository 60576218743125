import React, { useState, useEffect } from "react";
import { useHistory, useLocation, Link } from 'react-router-dom';
import homeLogo from "../assets/images/home_icon.svg"
import rightArrow from "../assets/images/right_arrow.svg"
import pathNames from "../router/RoutePath";
import { useDispatch, useSelector } from 'react-redux';
import { FetchJurisdictionsAction } from '../redux/actions/KnackActions';
import backIcon from "../assets/images/back_errow.svg"

export default function SearchResult() {
    let [constraints, setConstraints] = useState({
        page: "1",
        limit: "50",
        order: "asc",
        filters: ""
    });
    let history = useHistory();
    let location = useLocation();
    let [jurisdictionsRecord, setJurisdictionsRecord] = useState('');
    let [jurisdictionsFilter, setJurisdictionsFilter] = useState('');
    let [queryFilter, setQueryFilter] = useState('');
    let [counterSearchStatus, setCounterSearchStatus] = useState(false);
    let [currentPage, setCurrentPage] = useState('');
    let [totalPages, setTotalPages] = useState('');
    let [totalRecords, setTotalRecords] = useState('');
    let [toRecords, setToRecords] = useState('');
    let [fromRecords, setFromRecords] = useState('');
    let [nextPage, setNextPage] = useState('');
    let [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const FetchJurisdictionsData = (obj) => {
        dispatch(FetchJurisdictionsAction(obj));
    };

    const handleFieldChange = e => {
        setLoading(true);
        setCounterSearchStatus(false);
        setJurisdictionsFilter(e.target.value);
    }

    const nextPageData = (e) => {
        e.preventDefault();
        setLoading(true);
        setNextPage(currentPage + 1);
    };

    const previousPageData = (e) => {
        e.preventDefault();
        setLoading(true);
        setNextPage(currentPage - 1);
    };

    useEffect(() => {
        // console.log(location);
        if (location && location.state === true) {
            setLoading(true);
            setCounterSearchStatus(true);
            setQueryFilter(location.query);
            // setJurisdictionsFilter(location.query);
        }
        else {
            setCounterSearchStatus(false);
        }
    }, []);

    let counterJurisdictions = useSelector((state) => state.knackResult.knackResponse);
    let counterSearchJurisdictions = useSelector((state) => state.knackResult.JurisdictionSearchResults);
    // Fetch Knack APIs Data
    useEffect(() => {
        if (counterSearchJurisdictions !== '' && counterSearchStatus === true) {
            let status = counterSearchJurisdictions.status;
            if (status === true) {
                setJurisdictionsRecord(counterSearchJurisdictions.data.data);
                setCurrentPage(counterSearchJurisdictions.data.current_page);
                setTotalPages(counterSearchJurisdictions.data.last_page);
                setTotalRecords(counterSearchJurisdictions.data.total);
                setToRecords(counterSearchJurisdictions.data.to);
                setFromRecords(counterSearchJurisdictions.data.from);
                setLoading(false)
            }
            else if (status === false) {
                console.log(counterSearchJurisdictions.message);
            }
        } else if (counterJurisdictions !== '' && counterSearchStatus === false) {
            let status = counterJurisdictions.status;
            if (status === true) {
                setJurisdictionsRecord(counterJurisdictions.data.data);
                setCurrentPage(counterJurisdictions.data.current_page);
                setTotalPages(counterJurisdictions.data.last_page);
                setTotalRecords(counterJurisdictions.data.total);
                setToRecords(counterJurisdictions.data.to);
                setFromRecords(counterJurisdictions.data.from);
                setLoading(false)
            }
            else if (status === false) {
                console.log(counterJurisdictions.message);
            }
        }
        else {
            return
        }
    }, [counterJurisdictions, counterSearchJurisdictions])

    useEffect(() => {
        if (nextPage && nextPage >= 1) {
            setLoading(true);
            let obj = { ...constraints };
            obj.page = nextPage;
            obj.limit = '50';
            obj.order = 'asc';
            obj.filters = queryFilter;
            setConstraints({ ...obj });
            FetchJurisdictionsData(obj);
        }
    }, [nextPage])


    useEffect(() => {
        if (jurisdictionsFilter && jurisdictionsFilter.length > 1 && (jurisdictionsFilter !== null || jurisdictionsFilter !== '') && counterSearchStatus === false) {
            let filterObj = { ...constraints };
            filterObj.page = '1';
            filterObj.limit = '50';
            filterObj.order = 'asc';
            filterObj.filters = jurisdictionsFilter;
            setConstraints({ ...filterObj });
            FetchJurisdictionsData(filterObj);
        }
        else if (counterSearchStatus === false) {
            let filterObj = { ...constraints };
            filterObj.page = '1';
            filterObj.limit = '50';
            filterObj.order = 'asc';
            filterObj.filters = '';
            setConstraints({ ...filterObj });
            FetchJurisdictionsData(filterObj);
        }
    }, [jurisdictionsFilter])

    return (
        <section>
            <div className="searchPart">
                <div className="searchBox extraBox">
                    <h5>Jurisdictions</h5>
                    <p>Enter County Name, State Abbreviation or Jurisdiction Name</p>
                    <div className="displayDiv">
                        <div className="pseudo-search mt-3">
                            <input type="text" placeholder="Search" minLength={2} onChange={handleFieldChange} />
                            <button className="fa fa-search" type="submit"></button>
                        </div>
                    </div>
                    <div className="searchLink">
                        <span>
                            or Search by
                            <Link to={pathNames.states}>States,</Link>
                            <Link to={pathNames.CountiesPage}>Counties,</Link>
                            <Link to={pathNames.jurisdictions}>Jurisdictions</Link>
                        </span>
                    </div>
                </div>
            </div>
            <div className="resultJurisdictions">
                <div className="dataOption">
                    <div className="homeDiv mainResults">
                        <img src={homeLogo} alt="" />
                        <img src={rightArrow} alt="" />
                        <h6>Jurisdictions</h6>
                    </div>
                    <button className='backButton' onClick={() => history.goBack()}><i className="fa fa-arrow-circle-left" />Go Back</button>
                </div>
                <h5>Jurisdictions Data</h5>
                <div>
                    {loading ? <div className="loader"></div> :
                        <div>
                            <table responsive={"responsive"} className="table table-bordered">
                                <thead className="table-head">
                                    <tr>
                                        <th scope="col">State</th>
                                        <th scope="col">County</th>
                                        <th scope="col">Jurisdiction</th>
                                        <th scope="col">Address</th>
                                    </tr>
                                </thead>
                                <tbody className="placeTitle">
                                    {jurisdictionsRecord.length > 0 && jurisdictionsRecord.map((jurisdictionRecord, index) => (
                                        <tr data-index={index} key={index}>
                                            <td><Link to={
                                                {
                                                    pathname: pathNames.StateDetail,
                                                    state: jurisdictionRecord.state_id
                                                }
                                            } className="placeName">{jurisdictionRecord.state_name}</Link></td>
                                            <td><Link to={
                                                {
                                                    pathname: pathNames.CountyDetails,
                                                    state: jurisdictionRecord.county_id
                                                }
                                            } className="placeName">{jurisdictionRecord.county_name}</Link></td>
                                            <td><Link to={
                                                {
                                                    pathname: pathNames.JurisdictionDetails,
                                                    state: [{ jurisdiction_id: jurisdictionRecord.jurisdiction_id }, { county_id: jurisdictionRecord.county_id }]
                                                }
                                            } className="placeName">{jurisdictionRecord.jurisdiction_name}</Link></td>
                                            <td>{jurisdictionRecord.jurisdiction_address}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="col-sm-12 mainPagination">
                                <div className='paginationBar'>
                                    <span className="paginationButtons">
                                        {((currentPage > 1) && <button className="fa fa-chevron-left paginationArrow" type="button" onClick={previousPageData}></button>)}
                                        <button className='paginationNumber'>{currentPage}</button>
                                        {((currentPage < totalPages) && <button className="fa fa-chevron-right paginationArrow" type="button" onClick={nextPageData}></button>
                                        )}
                                    </span>
                                    <span>of {totalPages} pages</span>
                                </div>
                                <p className='m-0'>Total Records: {totalRecords} Showing  {fromRecords}-{toRecords} of {totalRecords}</p>
                            </div>
                            {(((jurisdictionsRecord.length === 0 || jurisdictionsRecord.length <= 0) && counterSearchStatus === false) && <span className='error'>No Records Available</span>
                            )}
                            {(((jurisdictionsRecord.length === 0 || jurisdictionsRecord.length <= 0) && counterSearchStatus === true) && <span className='error'>No records available against your searched parameters</span>
                            )}
                        </div>
                    }
                </div>
                <Link className="goBack" to={pathNames.mainSearch}>
                    <span><img className="eyeIcon" src={backIcon} alt="eyeIcon" /></span>
                    <span>Back to Main Search</span>
                </Link>
            </div>
        </section >

    )
}