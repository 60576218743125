import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from 'react-router-dom';
import homeLogo from "../assets/images/home_icon.svg"
import rightArrow from "../assets/images/right_arrow.svg"
import backIcon from "../assets/images/back_errow.svg"
import pathNames from "../router/RoutePath";
import {
    FetchCountyAction,
    FetchCountyJurisdictionsAction
} from '../redux/actions/KnackActions';
import { useDispatch, useSelector } from 'react-redux';

export default function CountyDetail() {
    let location = useLocation();
    let history = useHistory();
    let countyId = location.state;
    let [constraints, setConstraints] = useState({
        page: "1",
        limit: "50",
        order: "asc",
        county: countyId,
        filters: ""
    });

    let [loading, setLoading] = useState(false);
    let [loadingCountyJurisdictions, setLoadingCountyJurisdictions] = useState(false);
    let [countyData, setCountyData] = useState('');
    let [countyJurisdictionsRecord, setCountyJurisdictionsRecord] = useState('');
    let [countiesFilter, setCountiesFilter] = useState('');
    let [currentCountyJurisdictionsPage, setCurrentCountyJurisdictionsPage] = useState('');
    let [totalCountyJurisdictionsPages, setTotalCountyJurisdictionsPages] = useState('');
    let [totalCountyJurisdictionsRecords, setCountyJurisdictionsRecords] = useState('');
    let [toRecords, setToRecords] = useState('');
    let [fromRecords, setFromRecords] = useState('');
    let [nextPage, setNextPage] = useState('');
    const dispatch = useDispatch();
    const FetchCountyData = (id) => {
        dispatch(FetchCountyAction(id));
    };

    const FetchCountyJurisdictionsData = (obj) => {
        dispatch(FetchCountyJurisdictionsAction(obj));
    }

    useEffect(() => {
        setLoading(true);
        setLoadingCountyJurisdictions(true)
        FetchCountyData(countyId);
        FetchCountyJurisdictionsData(constraints);
    }, []);

    let counterCounty = useSelector((state) => state.knackResult.CountyData);
    let counterCountyJurisdictions = useSelector((state) => state.knackResult.CountyJurisdictionsData);

    // Fetch Knack APIs Data
    useEffect(() => {
        if (counterCounty !== '') {
            let status = counterCounty.status;
            if (status === true) {
                let data = counterCounty.data;
                setCountyData(data);
                setLoading(false);
            }
            else if (status === false) {
                console.log(counterCounty.message);
            }
        }
    }, [counterCounty])

    // Fetch Knack APIs Data
    useEffect(() => {
        if (counterCountyJurisdictions !== '') {
            let status = counterCountyJurisdictions.status;
            if (status === true) {
                setCountyJurisdictionsRecord(counterCountyJurisdictions.data.data);
                setCurrentCountyJurisdictionsPage(counterCountyJurisdictions.data.current_page);
                setTotalCountyJurisdictionsPages(counterCountyJurisdictions.data.last_page);
                setCountyJurisdictionsRecords(counterCountyJurisdictions.data.total);
                setToRecords(counterCountyJurisdictions.data.to);
                setFromRecords(counterCountyJurisdictions.data.from);
                setLoadingCountyJurisdictions(false);
            }
            else if (status === false) {
                console.log(counterCountyJurisdictions.message);
            }
        }
        else {
            return
        }
    }, [counterCountyJurisdictions])

    const nextPageData = (e) => {
        e.preventDefault();
        setLoadingCountyJurisdictions(true);
        setNextPage(currentCountyJurisdictionsPage + 1);
    };

    const previousPageData = (e) => {
        e.preventDefault();
        setLoadingCountyJurisdictions(true);
        setNextPage(currentCountyJurisdictionsPage - 1);
    };

    useEffect(() => {
        if (nextPage && nextPage >= 1) {
            setLoadingCountyJurisdictions(true);
            let obj = { ...constraints };
            obj.page = nextPage;
            obj.limit = '50';
            obj.order = 'asc';
            obj.county = countyId;
            obj.filters = '';
            setConstraints({ ...obj });
            FetchCountyJurisdictionsData(obj);
        }
    }, [nextPage])
    return (
        <section>
            <div className="searchPart">
                <div className="searchBox mainDetail">
                    <div>
                        <h5>County Details</h5>
                    </div>
                </div>
            </div>
            <div className="JurisdictionDetail">
                <div className="dataOption">
                    <div className="homeDiv itemDiv">
                        <img src={homeLogo} alt="" />
                        <img src={rightArrow} alt="" />
                        <Link className="placeName" to={pathNames.CountiesPage}> <h6>Counties</h6></Link>
                        <img src={rightArrow} alt="" />
                        <h6>County Details</h6>
                    </div>
                    <button className='backButton' onClick={() => history.goBack()}><i className="fa fa-arrow-circle-left" />Go Back</button>
                </div>
                <br />
                {loading ? <div className="loader"></div> :
                    <div id="JurisdictionMainData">
                        <h6>County Details</h6>
                        <div className="row">
                            <div className="col-md-6">
                                <label className="formLabel" htmlFor="formGroupExampleInput">State</label>
                                <input style={{ backgroundColor: '#edf4ef' }} type="text" className="form-control" value={countyData.state_name} readOnly />
                            </div>
                            <div className="col-md-6">
                                <label className="formLabel" htmlFor="formGroupExampleInput">County Name</label>
                                <input style={{ backgroundColor: '#edf4ef' }} type="text" className="form-control" value={countyData.county_name} readOnly />
                            </div>
                        </div>
                    </div>
                }
                <br />
                <h6>{countyData.county_name}'s Counties Data</h6>
                {loadingCountyJurisdictions ? <div className="loader"></div> :
                    <div className="row">
                        <div className="col-md-12">
                            <table responsive={"responsive"} className="table table-bordered">
                                <thead className="table-head">
                                    <tr>
                                        <th scope="col">State</th>
                                        <th scope="col">County</th>
                                        <th scope="col">Jurisdiction</th>
                                        <th scope="col">Address</th>
                                    </tr>
                                </thead>
                                <tbody className="placeTitle">
                                    {countyJurisdictionsRecord.length > 0 && countyJurisdictionsRecord.map((jurisdictionRecord, index) => (
                                        <tr data-index={index} key={index}>
                                            <td><Link to={
                                                {
                                                    pathname: pathNames.StateDetail,
                                                    state: jurisdictionRecord.state_id
                                                }
                                            } className="placeName">{jurisdictionRecord.state_name}</Link></td>
                                            <td><Link to={
                                                {
                                                    pathname: pathNames.CountyDetails,
                                                    state: jurisdictionRecord.county_id
                                                }
                                            } className="placeName">{jurisdictionRecord.county_name}</Link></td>
                                            <td><Link to={
                                                {
                                                    pathname: pathNames.JurisdictionDetails,
                                                    state: [{ jurisdiction_id: jurisdictionRecord.jurisdiction_id }, { county_id: jurisdictionRecord.county_id }]
                                                }
                                            } className="placeName">{jurisdictionRecord.jurisdiction_name}</Link></td>
                                            <td>{jurisdictionRecord.jurisdiction_address}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="col-md-12 mainPagination">
                                <div className='paginationBar'>
                                    <span className="paginationButtons">
                                        {((currentCountyJurisdictionsPage > 1) && <button className="fa fa-chevron-left paginationArrow" type="button" onClick={previousPageData}></button>)}
                                        <button className='paginationNumber'>{currentCountyJurisdictionsPage}</button>
                                        {((currentCountyJurisdictionsPage < totalCountyJurisdictionsRecords) && <button className="fa fa-chevron-right paginationArrow" type="button" onClick={nextPageData}></button>
                                        )}
                                    </span>
                                    {/* <span>of {totalCountyJurisdictionsRecords} pages</span> */}
                                </div>
                                <p className='m-0'>Total Records: {totalCountyJurisdictionsRecords} Showing  {fromRecords}-{toRecords} of {totalCountyJurisdictionsRecords}</p>
                            </div>
                            {((countyJurisdictionsRecord.length === 0 || countyJurisdictionsRecord.length <= 0) && <span className='error'>No Records Available</span>
                            )}
                        </div>
                    </div>
                }
                <Link className="goBack" to={pathNames.CountiesPage}>
                    <span><img className="eyeIcon" src={backIcon} alt="eyeIcon" /></span>
                    <span>Back to Counties</span>
                </Link>
            </div>
        </section>
    )
}