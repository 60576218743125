import React, { useState, useEffect } from "react";
import { Alert } from 'react-bootstrap';
import { useHistory, Link, useLocation } from 'react-router-dom';
import homeLogo from "../assets/images/home_icon.svg"
import rightArrow from "../assets/images/right_arrow.svg"
import backIcon from "../assets/images/back_errow.svg"
import exportBox from "../assets/images/export_box.svg"
import pathNames from "../router/RoutePath";
import {
    ExportPdfDataAction,
} from '../redux/actions/KnackActions';
import { FetchUserSubscriptionDataAction } from '../redux/actions/PaymentActions';
import { useDispatch, useSelector } from 'react-redux';
import { baseUrl, fetchUserProfile } from "../constant";
import PpLogo from "../assets/images/pp.png";
import axios from 'axios';
import DownloadPdf from "../components/DownloadPdf";


export default function ExportDetails() {

    let location = useLocation();
    let history = useHistory();
    const requirementId = location.state[0].requirement_id;
    const jurisdictionId = location.state[1].jurisdiction_id;
    let [projectName, setProjectName] = useState('');
    let [projectNumber, setProjectNumber] = useState('');
    let [projectScope, setProjectScope] = useState('');
    let [projectAddress, setProjectAddress] = useState('');
    let [projectFootage, setProjectFootage] = useState('');
    let [projectValuation, setProjectValuation] = useState('');
    let [contactName, setContactName] = useState('');
    let [contactTitle, setContactTitle] = useState('');
    let [contactPhone, setContactPhone] = useState('');
    let [contactEmail, setContactEmail] = useState('');
    let [loading, setLoading] = useState(false);
    let [docLogo, setDocLogo] = useState('');
    let [uploadLogo, setUploadLogo] = useState(false);
    let [pdfErrorAlert, setPdfErrorAlert] = useState(false);
    let [pdfErrorMessage, setPdfErrorMessage] = useState("");
    let [pdfSuccessAlert, setPdfSuccessAlert] = useState(false);
    let [pdfSuccessMessage, setPdfSuccessMessage] = useState("");
    let [pdfDownloadLink, setPdfDownloadLink] = useState("");
    const dispatch = useDispatch();
    const [fields, setState] = useState({
        project_name: "",
        project_number: "",
        project_valuation: "",
        project_square_footage: "",
        project_address: "",
        project_scope: "",
        contact_name: "",
        contact_title: "",
        contact_email: "",
        contact_phone: "",
        requirement_id: "",
        jurisdiction_id: "",
    });

    const ExportPdfData = (e) => {
        setLoading(true);
        e.preventDefault();
        let updateObj = { ...fields };
        updateObj.project_name = projectName;
        updateObj.project_number = projectNumber;
        updateObj.project_valuation = projectValuation;
        updateObj.project_square_footage = projectFootage;
        updateObj.project_address = projectAddress;
        updateObj.project_scope = projectScope;
        updateObj.contact_name = contactName;
        updateObj.contact_title = contactTitle;
        updateObj.contact_email = contactEmail;
        updateObj.contact_phone = contactPhone;
        updateObj.requirement_id = requirementId;
        updateObj.jurisdiction_id = jurisdictionId;
        setState({ ...updateObj });
        dispatch(ExportPdfDataAction(updateObj));
    }

    const FetchUserSubscriptionData = () => {
        dispatch(FetchUserSubscriptionDataAction());
    };

    useEffect(() => {
        setLoading(true);
        FetchUserSubscriptionData();
    }, []);

    let counterExportPdf = useSelector((state) => state.knackResult.ExportPdf);
    let counterUserSubscription = useSelector((state) => state.PaymentResult.UserSubscriptionResponse);

    // Fetch Knack APIs Data
    useEffect(() => {
        if (counterUserSubscription && counterUserSubscription.status) {
            if (counterUserSubscription.data !== null) {
                if (counterUserSubscription.data.client_product_name === 'Professional') {
                    setUploadLogo(true);
                    getUserLogo();
                }
                else {
                    setUploadLogo(false);
                    setDocLogo(PpLogo);
                }
            }
            else {
                setUploadLogo(false);
                setDocLogo(PpLogo);
            }
        }
        else {
            setUploadLogo(false);
            setDocLogo(PpLogo);
        }
        if (counterExportPdf && counterExportPdf.status) {
            if (counterExportPdf.data !== null) {
                setPdfSuccessMessage('PDF Generated. Download from below button.');
                setPdfDownloadLink(counterExportPdf.data.pdf_download_link);
            }
            else {
                setPdfErrorMessage('Something went wrong. Please Try again.');
            }
        }
        setLoading(false);
    }, [counterUserSubscription, counterExportPdf]);

    useEffect(() => {
        console.log(pdfDownloadLink);
    }, [pdfDownloadLink]);

    const handleFieldChange = e => {
        if (e.target.value !== "") {
            AssignProjectValues(e.target.id, e.target.value);
        }
    }

    const AssignProjectValues = (inputName, inputValue) => {
        switch (inputName) {
            case 'project_name':
                setProjectName(inputValue);
                break;
            case 'project_number':
                setProjectNumber(inputValue);
                break;
            case 'project_scope':
                setProjectScope(inputValue);
                break;
            case 'project_address':
                setProjectAddress(inputValue);
                break;
            case 'project_square_footage':
                setProjectFootage(inputValue);
                break;
            case 'project_valuation':
                setProjectValuation(inputValue);
                break;
            case 'contact_name':
                setContactName(inputValue);
                break;
            case 'contact_title':
                setContactTitle(inputValue);
                break;
            case 'contact_phone':
                setContactPhone(inputValue);
                break;
            case 'contact_email':
                setContactEmail(inputValue);
                break;
            default:
                break;
        }
    };

    function resetForm() {
        document.getElementById("export-details").reset();
    }

    const getUserLogo = async () => {
        const token = await localStorage.getItem('user-token');
        const url = baseUrl + '/' + fetchUserProfile;
        return await axios.get(url, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'Access-Control-Allow-Origin': '*',
            },
        }).then(function (response) {
            if (response.data.status && response.data.data.logo !== null) {
                setDocLogo(baseUrl + '/' + response.data.data.logo);
            }
            else {
                setDocLogo(PpLogo);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    useEffect(() => {
        if (pdfSuccessMessage !== "") {
            setPdfSuccessAlert(true);
        }
        else {
            setPdfSuccessAlert(false);
        }
    }, [pdfSuccessMessage])

    useEffect(() => {
        if (pdfErrorMessage !== "") {
            setPdfErrorAlert(true);
        }
        else {
            setPdfErrorAlert(false);
        }
    }, [pdfErrorMessage])

    return (
        <section>
            <div className="searchPart">
            </div>
            <div className="submitRequestDetail">
                <div className="dataOption">
                    <div className="homeDiv detailDiv">
                        <img src={homeLogo} alt="" />
                        <img src={rightArrow} alt="" />
                        <Link className="placeName" to={pathNames.jurisdictions}> <h6>Jurisdiction</h6></Link>
                        <img src={rightArrow} alt="" />
                        <h6>Jurisdiction Details</h6>
                        <img src={rightArrow} alt="" />
                        <h6>Export Details</h6>
                    </div>
                    <button className='backButton' onClick={() => history.goBack()}><i className="fa fa-arrow-circle-left" />Go Back</button>
                </div>
                <div className="requestTitle">
                    <h5>Export Details</h5>
                    {/* <button className="exportBtn" onClick={exportPDF}> <img src={exportBox} alt="" /> Export</button> */}
                </div>
                {loading ? <div className="loader"></div> : <div className="row" >
                    {pdfSuccessAlert &&
                        <div className="row">
                            <div className="col-md-12">
                                {pdfSuccessMessage && pdfSuccessAlert !== false && (<Alert variant="success" onClose={() => setPdfSuccessAlert(false)} dismissible>{pdfSuccessMessage}</Alert>)}
                            </div>
                            <div className="col-md-4">
                                <DownloadPdf PdfLink={pdfDownloadLink} />
                            </div>
                        </div>
                    }
                    {pdfErrorAlert &&
                        <div className="row">
                            <div className="col-md-12">
                                {pdfErrorMessage && pdfErrorAlert !== false && (<Alert variant="success" onClose={() => setPdfErrorAlert(false)} dismissible>{pdfErrorMessage}</Alert>)}
                            </div>
                        </div>
                    }
                    <div className="col-md-12">
                        <form id="export-details" onSubmit={ExportPdfData} encType="multipart/form-data">
                            {uploadLogo &&
                                <div className="row">
                                    {/* Logo Information */}
                                    <div className="col-md-12">
                                        <h6>Logo Details</h6>
                                        {docLogo && <img id="doc-logo" src={docLogo} alt="Preview Unavailable" style={{ width: "200px", height: "60px" }} />}
                                    </div>
                                    <div className="col-md-12">
                                        {uploadLogo && <Link to={pathNames.ProfileInfo}>Click here to update logo</Link>}
                                    </div>
                                </div>
                            }
                            <div className="row">
                                {/* Project Information */}
                                <div className="col-md-12">
                                    <h6>Project Information</h6>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="formLabel" htmlFor="formGroupExampleInput">Project Name</label>
                                            <input style={{ backgroundColor: '#edf4ef' }} type="text" className="form-control" id="project_name" placeholder="Project Name" onKeyDown={handleFieldChange} />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="formLabel" htmlFor="formGroupExampleInput">Project Number</label>
                                            <input style={{ backgroundColor: '#edf4ef' }} type="text" className="form-control" id="project_number" placeholder="Project Number" onChange={handleFieldChange} />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="formLabel" htmlFor="formGroupExampleInput">Project Valuation</label>
                                            <input style={{ backgroundColor: '#edf4ef' }} type="text" className="form-control" id="project_valuation" placeholder="Project Valuation" onChange={handleFieldChange} />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="formLabel" htmlFor="formGroupExampleInput">Project Square Footage</label>
                                            <input style={{ backgroundColor: '#edf4ef' }} type="text" className="form-control" id="project_square_footage" placeholder="Project Square Footage" onChange={handleFieldChange} />
                                        </div>
                                        <div className="col-md-12">
                                            <label className="formLabel" htmlFor="formGroupExampleInput">Project Address</label>
                                            <textarea style={{ backgroundColor: '#edf4ef' }} type="text" className="form-control" id="project_address" placeholder="Project Address" onChange={handleFieldChange}></textarea>
                                        </div>
                                        <div className="col-md-12">
                                            <label className="formLabel" htmlFor="formGroupExampleInput">Project Scope of Work</label>
                                            <textarea style={{ backgroundColor: '#edf4ef' }} type="text" className="form-control" id="project_scope" placeholder="Project Scope of Work" onChange={handleFieldChange}></textarea>
                                        </div>
                                    </div>
                                </div>
                                {/* Contact Information */}
                                <div className="col-md-12">
                                    <br />
                                    <h6>Contact Information</h6>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="formLabel" htmlFor="formGroupExampleInput">Contact Name</label>
                                            <input style={{ backgroundColor: '#edf4ef' }} type="text" className="form-control" id="contact_name" placeholder="Contact Name" onKeyDown={handleFieldChange} />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="formLabel" htmlFor="formGroupExampleInput">Contact Title</label>
                                            <input style={{ backgroundColor: '#edf4ef' }} type="text" className="form-control" id="contact_title" placeholder="Contact Title" onChange={handleFieldChange} />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="formLabel" htmlFor="formGroupExampleInput">Contact Email</label>
                                            <input style={{ backgroundColor: '#edf4ef' }} type="text" className="form-control" id="contact_email" placeholder="Contact Email" onChange={handleFieldChange} />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="formLabel" htmlFor="formGroupExampleInput">Contact Phone</label>
                                            <input style={{ backgroundColor: '#edf4ef' }} type="text" className="form-control" id="contact_phone" placeholder="Contact Phone" onChange={handleFieldChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 mt-3">
                                    {loading ? <div className="loader"></div> :
                                        <button disabled={loading} className="btn-submit" type="submit">
                                            <span id="button-text">
                                                {loading ? <div className="loader"></div> : <div><i class="fa fa-file"></i> Generate PDF</div>}
                                            </span>
                                        </button>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                }
                <Link className="goBack" to={pathNames.jurisdictions}>
                    <span><img className="eyeIcon" src={backIcon} alt="eyeIcon" /></span>
                    <span>Back to Jurisdiction</span>
                </Link>
            </div>
        </section >

    )
}