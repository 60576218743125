import React, { useState, useEffect } from "react";
import { Alert } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import ProfilePic from "../assets/images/user_avatar.png"
import { useDispatch, useSelector } from 'react-redux';
import NoLogo from "../assets/images/nologo.png"
import {
    FetchProfileDataAction,
    UpdateProfileDataAction
} from '../redux/actions/AuthActions';
import { FetchUserSubscriptionDataAction } from '../redux/actions/PaymentActions';
import backIcon from "../assets/images/back_errow.svg"
import { post } from 'axios';
import { baseUrl, uploadImage } from "../constant";
export default function ProfileInfo() {
    const [fields, setState] = useState({
        id: "",
        name: "",
        email: "",
        phone: "",
        country: "",
        password: "",
        password_confirmation: "",
        description: "",
    });
    const dispatch = useDispatch();
    const history = useHistory();
    let [profileSuccessMessage, setProfileSuccessMessage] = useState("");
    let [userId, setUserId] = useState("");
    let [userLogo, setUserLogo] = useState("");
    let [loading, setLoading] = useState(false);
    let [uploadLogo, setUploadLogo] = useState(false);
    let [error, setError] = useState({
        name: "",
        email: "",
        phone: "",
        country: "",
        password: "",
        password_confirmation: "",
        description: "",
        logo: "",
    });
    let [logo, setLogo] = useState('');
    let [profileSuccessAlert, setProfileSuccessAlert] = useState(false);
    let [profileErrorAlert, setProfileErrorAlert] = useState(false);
    let [profileErrorMessage, setProfileErrorMessage] = useState("");
    const strongPasswordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/;
    const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    const nameRegex = /^[a-zA-Z ]*$/;
    const phoneRegex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;
    const FetchUserProfile = () => {
        setLoading(true);
        dispatch(FetchProfileDataAction(fields, history));
    };

    const handleFieldChange = e => {
        setProfileSuccessMessage("");
        let name = e.target.id;
        if (e.target.id === 'company-logo') {
            setLogo(URL.createObjectURL(e.target.files[0]));
            checkValidity(e.target.id, e.target.files[0]);
        } else {
            checkValidity(e.target.id, e.target.value);
            setState({
                ...fields,
                [e.target.id]: e.target.value
            });
        }
        if (e.target.value === "") {
            setError({ ...error, [name]: "" });
        }
    }

    const UserProfileUpdate = (e) => {
        e.preventDefault();
        let errorStringConfirmPassword = "Password and Confirm Password does not match.";
        if (!checkPasswordMatch(fields.password, fields.password_confirmation)) {
            setError({ ...error, password_confirmation: errorStringConfirmPassword });
        } else {
            let errorStatus = isErrorsObjectEmpty(error);
            if (errorStatus) {
                setProfileErrorMessage("");
                setLoading(true)
                dispatch(UpdateProfileDataAction(fields));
            }
            else {
                setProfileErrorMessage('Please Fulfill all requirements.');
            }
        }
    }

    const FetchUserSubscriptionData = () => {
        dispatch(FetchUserSubscriptionDataAction());
    };

    useEffect(() => {
        FetchUserSubscriptionData();
        FetchUserProfile();
    }, []);

    function fileUpload(image) {
        const url = baseUrl + '/' + uploadImage;
        const formData = new FormData();
        formData.append('image', image, image.name);
        formData.append('user', userId);
        return post(url, formData, {
            onUploadProgress: progressEvent => {
                console.log('Upload Progressing:' + Math.round((progressEvent.loaded / progressEvent.total) * 100) + '%');
            }
        }).then(function (response) {
            setLogo(baseUrl + '/' + response.data.data)
            document.getElementById('company-logo').value = '';
        }).catch(function (error) {
            console.log(error);
        });
    }

    function isErrorsObjectEmpty(obj) {
        if (obj.name.length === 0 && obj.country.length === 0 && obj.phone.length === 0 && obj.email.length === 0 && obj.password.length === 0) {
            return true;
        }
        return false;
    }

    const checkValidity = (inputName, inputValue) => {
        const errorStringName = "Your name must be more than 8 characters long. Should be letters only";
        const errorStringEmail = "Your email must be more than 8 characters long. Should be valid Email.";
        const errorStringPassword = "Your Password must be more than 8 characters long. Should contains at least 1 Uppercase, 1 Lowercase, 1 Number and 1 Special Character.";
        const errorStringPhone = "Your phone must be more than 11 characters long. Should be a valid Phone Number.";
        switch (inputName) {
            case "name":
                let nameValidator = nameRegex.test(inputValue);
                setError({ ...error, [inputName]: "" });
                inputValue.length < 8 ? setError({ ...error, [inputName]: errorStringName }) : setError({ ...error, [inputName]: "" });
                if (!nameValidator) setError({ ...error, [inputName]: errorStringName });
                // setUserName(inputValue);
                break;
            case "email":
                fields.email = emailRegex.test(inputValue);
                setError({ ...error, [inputName]: "" });
                inputValue.length < 8 ? setError({ ...error, [inputName]: errorStringEmail }) : setError({ ...error, [inputName]: "" });
                if (!fields.email) setError({ ...error, [inputName]: errorStringEmail });
                // setUserEmail(inputValue);
                break;
            case "password":
                fields.password = strongPasswordRegex.test(inputValue);
                setError({ ...error, [inputName]: "" });
                inputValue.length < 8 ? setError({ ...error, [inputName]: errorStringPassword }) : setError({ ...error, [inputName]: "" });
                if (!fields.password) setError({ ...error, [inputName]: errorStringPassword });
                // setUserPassword(inputValue);
                break;
            case "password_confirmation":
                fields.password_confirmation = strongPasswordRegex.test(inputValue);
                setError({ ...error, [inputName]: "" });
                inputValue.length < 8 ? setError({ ...error, [inputName]: errorStringPassword }) : setError({ ...error, [inputName]: "" });
                if (!fields.password_confirmation) setError({ ...error, [inputName]: errorStringPassword });
                // setUserConfirmPassword(inputValue);
                break;
            case "phone":
                fields.phone = phoneRegex.test(inputValue);
                setError({ ...error, [inputName]: "" });
                inputValue.length > 11 ? setError({ ...error, [inputName]: errorStringPhone }) : setError({ ...error, [inputName]: "" });
                if (!fields.phone) setError({ ...error, [inputName]: errorStringPhone });
                // setUserPhone(inputValue);
                break;
            case "country":
                setError({ ...error, [inputName]: "" });
                // setUserCountry(inputValue);
                break;
            case "description":
                setError({ ...error, [inputName]: "" });
                // setUserDescription(inputValue);
                break;
            case "company-logo":
                if (!inputValue) {
                    setError({ ...error, [inputName]: 'Please select image.' });
                }
                if (!inputValue.name.match(/\.(jpg|jpeg|png|gif|svg)$/)) {
                    setError({ ...error, [inputName]: 'Please select valid image.' });
                }
                setUserLogo(inputValue);
                break;
            default:
                break;
        }
    };

    let counterUserProfile = useSelector((state) => state.userAuth.UserProfile);
    let counterUserSubscription = useSelector((state) => state.PaymentResult.UserSubscriptionResponse);
    // Fetch User Data
    useEffect(() => {
        if (counterUserSubscription.status) {
            if (counterUserSubscription.data !== null) {
                if (counterUserSubscription.data.client_product_name === 'Professional') {
                    setUploadLogo(true);
                }
            }
            else {
                setUploadLogo(false);
            }
        }
        else {
            setUploadLogo(false);
        }
        if (counterUserProfile !== '') {
            let status = counterUserProfile.status;
            let message = counterUserProfile.message;
            if (status === true) {
                if (message === 'Profile Updated Successfully') {
                    setProfileSuccessMessage(message);
                }
                let data = counterUserProfile.data;
                let detailsObj = { ...fields };
                setUserId(data.id);
                detailsObj.id = data.id;
                detailsObj.name = data.name;
                detailsObj.email = data.email;
                if (data.phone !== null) {
                    detailsObj.phone = data.phone;
                }
                if (data.country !== null) {
                    detailsObj.country = data.country;
                }
                if (data.description !== null) {
                    detailsObj.description = data.description;
                }
                setState({ ...detailsObj });
                if (counterUserProfile.data.logo !== null) {
                    setLogo(baseUrl + '/' + counterUserProfile.data.logo)
                } else {
                    setLogo(NoLogo)
                }
                setLoading(false);
            }
            else if (status === false) {
                setLoading(false);
                setProfileErrorMessage(counterUserProfile.message);
            }
        }
        else {
            setLoading(false);
            return
        }
    }, [counterUserProfile])

    useEffect(() => {
        if (profileSuccessMessage !== "") {
            setProfileSuccessAlert(true);
        }
        else {
            setProfileSuccessAlert(false);
        }
    }, [profileSuccessMessage])

    useEffect(() => {
        if (profileErrorMessage !== "") {
            setProfileErrorAlert(true);
        }
        else {
            setProfileErrorAlert(false);
        }
    }, [profileErrorMessage])

    useEffect(() => {
        if (userLogo !== '') {
            fileUpload(userLogo);
        }
    }, [userLogo])

    const checkPasswordMatch = (password, password_confirmation) => {
        return password === password_confirmation ? true : false;
    }
    return (
        <section className="profilePage">
            <div className="profileBackground">
                <div className="profileIntro">
                    <img className="profilePic" src={ProfilePic} alt="Resource Unavailable" />
                    <div className="aboutProfile">
                        <h6>Profile Information</h6>
                    </div>
                </div>
                <br />
                {profileSuccessMessage && profileSuccessAlert !== false && (<Alert variant="success" onClose={() => setProfileSuccessAlert(false)} dismissible>{profileSuccessMessage}</Alert>)}
                {profileErrorMessage && profileErrorAlert !== false && (<Alert variant="danger" onClose={() => setProfileErrorAlert(false)} dismissible>{profileErrorMessage}</Alert>)}
                <form id="profile-form" onSubmit={UserProfileUpdate} encType="multipart/form-data">
                    {uploadLogo &&
                        <div className="row inputRow">
                            <div className="col-sm-12 mt-3">
                                {logo && <img src={logo} alt="Preview Unavailable" style={{ width: "150px", height: "80px" }} />}
                            </div>
                            <div className="col-sm-6 mt-3">
                                <label htmlFor="logo" className="formLabel">Company Logo</label>
                                <input type="file" className="form-control inputBackground" id="company-logo" onChange={handleFieldChange} />
                                {error.logo.length > 0 && <span className='error'>{error.logo}</span>}
                            </div>
                        </div>
                    }
                    <div className="row inputRow">
                        <div className="col-sm-6 mt-3">
                            <label className="formLabel">Name *</label>
                            <input type="text" className="form-control inputBackground" id="name" onChange={handleFieldChange} value={fields.name} placeholder="John Doe" required="required" />
                            {error.name.length > 0 && <span className='error'>{error.name}</span>}
                        </div>
                        <div className="col-sm-6 mt-3">
                            <label className="formLabel">Email *</label>
                            <input type="text" className="form-control inputBackground" id="email" onChange={handleFieldChange} value={fields.email} placeholder="johndoe@gmail.com" required="required" />
                            {error.email.length > 0 && <span className='error'>{error.email}</span>}
                        </div>
                        <div className="col-sm-6 mt-3">
                            <label className="formLabel"  >Phone no</label>
                            <input type="text" className="form-control inputBackground" id="phone" onChange={handleFieldChange} value={fields.phone} placeholder="021-5215-4521" />
                            {error.phone.length > 0 && <span className='error'>{error.phone}</span>}
                        </div>
                        <div className="col-sm-6 mt-3">
                            <label className="formLabel"  >Country</label>
                            <input type="text" className="form-control inputBackground" id="country" onChange={handleFieldChange} value={fields.country} placeholder="USA" />
                            {error.country.length > 0 && <span className='error'>{error.country}</span>}
                        </div>
                        <div className="col-sm-6 mt-3">
                            <label className="formLabel"  >Password</label>
                            <input type="password" className="form-control inputBackground" id="password" onChange={handleFieldChange} placeholder="*******" />
                            {error.password.length > 0 && <span className='error'>{error.password}</span>}
                        </div>
                        <div className="col-sm-6 mt-3">
                            <label className="formLabel"  >Confirm Password</label>
                            <input type="password" className="form-control inputBackground" id="password_confirmation" onChange={handleFieldChange} placeholder="*****" />
                            {error.password_confirmation.length > 0 && <span className='error'>{error.password_confirmation}</span>}
                        </div>
                        <div className="col-sm-12 mt-3">
                            <label className="formLabel" htmlFor="description">About</label>
                            <textarea className="form-control commentBox" id="description" onChange={handleFieldChange} value={fields.description} rows="4"></textarea>
                            {error.description.length > 0 && <span className='error'>{error.description}</span>}
                        </div>
                        <div className="col-sm-4 mt-3">
                            {loading ? <div className="loader"></div> :
                                <button disabled={loading} className="btn-submit" type="submit">
                                    <span id="button-text">
                                        {loading ? <div className="loader"></div> : "Update Profile"}
                                    </span>
                                </button>
                            }
                        </div>
                    </div>
                </form>
            </div >
        </section >
    )
}