import React, { useEffect, useState } from "react";
import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import { Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
    UpdateUserSubscriptionAction
} from '../redux/actions/PaymentActions';
import { useHistory } from 'react-router-dom';

const CheckoutForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const history = useHistory();
    const [checkoutMessage, setCheckoutMessage] = useState(null);
    const [isLoadingElement, setIsLoadingElement] = useState(false);
    let [paymentErrorAlert, setPaymentErrorAlert] = useState(false);
    let [paymentSuccessAlert, setPaymentSuccessAlert] = useState(false);
    const dispatch = useDispatch();
    const subsId = props.Subscription;

    useEffect(() => {
        if (!stripe) {
            return;
        }
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!stripe || !elements) {
            return;
        }

        setIsLoadingElement(true);
        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            redirect: 'if_required'
        });

        if (error && (error.type === "card_error" || error.type === "validation_error" || error.type === "invalid_request_error")) {
            setCheckoutMessage(error.message);
            setPaymentErrorAlert(true);
            elements.getElement(PaymentElement).clear();
            setIsLoadingElement(false);
        } else if (error) {
            setCheckoutMessage("An unexpected Error occurred.");
            setPaymentErrorAlert(true);
            elements.getElement(PaymentElement).clear();
            setIsLoadingElement(false);
        } else if (paymentIntent) {
            switch (paymentIntent.status) {
                case "succeeded":
                    UpdateUserSubscription(subsId);
                    break;
                case "processing":
                    setCheckoutMessage("Your Payment is Processing.");
                    setPaymentSuccessAlert(true);
                    setPaymentErrorAlert(false);
                    elements.getElement(PaymentElement).clear();
                    setIsLoadingElement(false);
                    break;
                case "requires_payment_method":
                    setCheckoutMessage("Your Payment was not Successful, Please try again.");
                    setPaymentSuccessAlert(false);
                    setPaymentErrorAlert(true);
                    setIsLoadingElement(false);
                    elements.getElement(PaymentElement).clear();
                    break;
                default:
                    setCheckoutMessage("Something went Wrong.");
                    setPaymentSuccessAlert(false);
                    setPaymentErrorAlert(true);
                    setIsLoadingElement(false);
                    elements.getElement(PaymentElement).clear();
                    break;
            }
        }
    };

    const UpdateUserSubscription = (sub) => {
        dispatch(UpdateUserSubscriptionAction(sub));
    };

    let counterUpdateSubscription = useSelector((state) => state.PaymentResult.SubscriptionUpdate);

    useEffect(() => {
        if (counterUpdateSubscription && counterUpdateSubscription.status) {
            setCheckoutMessage("Payment Succeeded!");
            setPaymentSuccessAlert(true);
            setPaymentErrorAlert(false);
            elements.getElement(PaymentElement).clear();
            setIsLoadingElement(false);
            history.push('/subscribed')
        }
        else if (counterUpdateSubscription && !counterUpdateSubscription.status) {
            setCheckoutMessage("Your Payment was successfully deduct, But unable to update your subscription. Please contact site administrator.");
            setPaymentSuccessAlert(false);
            setPaymentErrorAlert(true);
            setIsLoadingElement(false);
            elements.getElement(PaymentElement).clear();
        }
    }, [counterUpdateSubscription])

    return (
        <div className='row'>
            <div className='col-md-12'>
                <form id='checkout-form' onSubmit={handleSubmit}>
                    <div className="row inputRow">
                        <div className="col-sm-12 mt-3">
                            {checkoutMessage && paymentErrorAlert !== false && (<Alert variant="danger" onClose={() => setPaymentErrorAlert(false)} dismissible>{checkoutMessage}</Alert>)}
                            {checkoutMessage && paymentSuccessAlert !== false && (<Alert variant="success" onClose={() => setPaymentSuccessAlert(false)} dismissible>{checkoutMessage}</Alert>)}
                        </div>
                    </div>
                    <div className="row inputRow">
                        <div className="col-sm-12 mt-3">
                            <PaymentElement id='payment-element' />
                        </div>
                        <div className="col-sm-12 mt-3">
                            {isLoadingElement ? <div className="loader"></div> :
                                <button disabled={isLoadingElement || !stripe || !elements} className="btn-submit" type="submit">
                                    <span id="button-text">
                                        {isLoadingElement ? <div className="loader"></div> : "Pay"}
                                    </span>
                                </button>
                            }
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default CheckoutForm;