import React from "react";
import ModalPopup from "../../components/ModalPopup";
import Logo from "../../assets/images/Logo.svg";
import pathNames from "../../router/RoutePath";


export default function Registered() {
    return (
        <section className="loginSection">
            <div className="container-fluid g-0">
                <div className="row g-0">
                    <div className="col-md-6" >
                        <div className="backgroundImage"></div>
                    </div>
                    <div className="col-md-6" >
                        <div className="mainLogin">
                            <div className="logo">
                                <img src={Logo} alt="Logo Unavailable" />
                            </div>
                            <div className="mainContent">
                                <ModalPopup Message='You Registered Successfully. Check your Email for account activation.' LinkMessage="Click to Login" LinkAnchor={pathNames.login} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}